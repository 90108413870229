<template>
  <!-- //用户端医技-->
    <div class="con">
      <div class="cll-left">
        <div style="display: flex;flex-flow: column nowrap;align-items: center;">
          <div class="left-item" v-for="(item, index) in menuLeftList" :key="index" @click="changeTarget(item, index)">
            <div class="left-img" :class="{'active': menuIndex == index}">
              <img :src="item.icon" alt="Menu">
            </div>
            <div class="left-item-title" >{{item.name}}</div>
          </div>
        </div>
        <img src="@/assets/image/pa1.png" alt="BG" style="width: 100%;object-fit: contain;position: absolute;bottom: 0">
      </div>
        <div class="cll_con" v-if="doctor_info_type === 1 && is_doctorList === 1">
            <div class="title" style="display: flex;justify-content: space-between;">
                <div style="width: 5%;">
                    <i class="el-icon-back" style="font-weight: bold;" @click="back"></i> <span style="margin-left: 2%;"></span>
                </div>
                <div style="width:65%;">
                    <breadcrumb @home="show_home" :tabs="['医技']"></breadcrumb>
                </div>
                <div style="width: 30%;cursor: pointer;">
                  <span style="margin-left: 10%;" @click="default_doctor">排班列表</span>
                  <span style="margin-left: 2%;" @click="famous_doctor">名医列表</span>
                </div>
            </div>
            <div style="width: 100%;height: 10%;display: flex;align-items: center;">
                <el-select v-model="MenuListValue" placeholder="请选择分类" style="width: 30%;margin-left: 20px;" size="mini" @change="MenuListChange">
                    <el-option
                        v-for="item in secondMenuList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
              <div style="width: 20px;"></div>
                <el-select v-model="value" placeholder="地区" style="width: 20%;margin-right: 20px;" size="mini" @change="change">
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div style="background-color: #eeeeee;width: 100%;height: 10%;" v-if="thirdMenuList_type === 2">
                <div style="display: flex;justify-content: space-between;align-items: center;overflow-x: auto;white-space: nowrap;height: 100%;">
                    <el-menu default-active="1" class="el-menu-demo" mode="horizontal" @select="thirdSelect" background-color="#eeeeee" style="display: flex;align-items: center;width: fit-content;">
                        <el-menu-item style="display: flex;align-items: center;font-size: 10px;" :index="'item2-'+index2" v-for="(item2,index2) in thirdMenuList" :key="index2">
                            {{ item2.name }}</el-menu-item>
                    </el-menu>
                </div>
            </div>
            <div class="header-container">
                <el-menu :default-active="activeIndex"  class="el-menu-demo" mode="horizontal" background-color="#eeeeee" @select="handleSelect" style="height: 100%;">
                    <el-menu-item style="height: 100%;position: relative;display: flex;align-items: center;justify-content: center;" :index="'item-'+index" class="menu-item" v-for="(item,index) in weekArray" :key="index">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;position: absolute;top: -1rem;">
                            <div style="height: 1.1rem;font-size: 10px;">{{ item.dayOfWeek }}</div>
                            <div style="height: 0.1rem;font-size: 10px;">{{ item.date.slice(5) }}</div>
                        </div>
                    </el-menu-item>
                </el-menu>
            </div>
            <div style="width: 96%;height: 53%;margin: auto;display: flex;justify-content: left;align-items: center;flex-wrap: wrap;overflow-y: auto;padding: 0 2%;">
                <div style="width: 20%;height: 90%;border-radius: 12px;border: 2px #0e92dd solid;margin: 1% 2%;" v-for="(item,index) in doctorList" :key="index" @click="doctor_info(item)">
                    <div style="width: 100%;height: 65%;border-radius: 12px 12px 0 0;">
                        <img :src="item.getDoctor.pic" alt="Carousel Image" class="full-width-image" style="width: 100%;height: 100%;object-fit: cover;border-radius: 12px 12px 0 0;">
                    </div>
                    <div style="width: 100%;height: 35%;border-radius: 0 0 12px 12px;">
                        <div style="padding: 0.5rem 0;"> <span style="font-size: 11px;padding: 1rem;font-weight: bold;">{{ item.getDoctor.name }} </span><span style="font-size: 9px;color: #99a9bf;"> {{ item.getDoctor.title }}</span> </div>
                        <div style="font-size: 8px;padding: 0 1rem;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ item.getDoctor.label }}11111111111111111111</div>
                        <div style="font-size: 8px;padding: 0 1rem;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">出诊医院: {{ item.hospital.name }}</div>
                        <div style="font-size: 8px;padding: 0 1rem;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">出诊时间: {{ item.date }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cll_con" v-if="doctor_info_type === 1 && is_doctorList === 2">
            <div class="title" style="display: flex;justify-content: space-between;">
                <div style="width: 5%;">
                    <i class="el-icon-back" style="font-weight: bold;" @click="back"></i> <span style="margin-left: 2%;"></span>
                </div>
                <div style="width:65%;">
                    <breadcrumb @home="show_home" :tabs="['医技']"></breadcrumb>
                </div>
                <div style="width: 30%;cursor: pointer;">
                  <span style="margin-left: 10%;" @click="default_doctor">排班列表</span>
                  <span style="margin-left: 2%;" @click="famous_doctor" >名医列表</span>
                </div>
            </div>
          <div style="width: 96%;height: 90%;margin: auto;display: flex;flex-wrap: wrap;overflow-y: auto;padding: 0 2%;">
            <div class="doc-item" style="width: 22%;min-height: 320px;border-radius: 12px;box-shadow: 0 0 10px 2px #efefefaa;box-sizing: border-box;margin: 1% 2%;" v-for="(item,index) in FamousDoctorsArray" :key="index" @click="doctor_infos(item)">
              <div style="width: 100%;height: 160px;border-radius: 12px 12px 0 0;position:relative;">
                <img :src="item.pic" alt="Carousel Image" class="full-width-image" style="width: 100%;object-fit: cover;z-index: 1;border-radius: 12px 12px 0 0;">
              </div>
              <div style="height: 160px;border-radius: 0 0 12px 12px;padding:0 1rem;display: flex;flex-direction: column;justify-content: space-evenly;position: relative;z-index: 2;background: #fff">
                <div style="margin:5px 0;">
                  <span style="font-size: 18px;font-weight: bold;">{{ item.name }} </span> <span>主治医师</span>
                </div>
                <div style="margin:5px 0;">
                  <span style="font-size: 14px;color: #99a9bf;"> {{ item.getHospital.name }}</span>
                </div>
                <!--                        <div style="font-size: 12px;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" v-if=" item.schedules !== [] ">排班时间: {{item.schedules[0].date}}</div>-->
                <!--                        <div style="font-size: 12px;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" v-else>暂无排班</div>-->
                <div>出诊时间:{{item.schedules[0] ? item.schedules[0].date : ''}}</div>
                <div style="text-align: center;color: #2F82BD;padding: 20px 0;cursor: pointer">查看详情</div>
              </div>
            </div>
          </div>
        </div>

        <div class="cll_con" v-if="doctor_info_type === 2 && is_doctorList === 1">
            <div class="title">
                <i class="el-icon-back" style="font-weight: bold;" @click="backDoctor"></i>
                <breadcrumb @home="show_home" :tabs="['医技', '医生详情']"></breadcrumb>
            </div>
            <div style="width: 95%;height: 80%;margin: 3% auto;display: flex;align-items: center;">
                <div style="width: 30%;height: 90%;border-radius: 12px;">
                    <img :src="doctor_info_array.getDoctor.pic" alt="Carousel Image" class="full-width-image" style="width: 100%;object-fit: cover;border-radius: 12px 12px 0 0;">
                </div>
                <div style="width: 70%;height: 90%;">
                    <div> <span style="font-size: 18px;color: #0e92dd;margin-left: 1.5rem;">{{ doctor_info_array.getDoctor.name }}</span> <span style="font-size: 13px;font-weight: bold;margin-left: 1.5rem;">{{ doctor_info_array.getDoctor.title }}</span> </div>
                    <div style="display: flex;margin-top: 1rem;margin-left: 1rem;">
                        <div style="padding: 2px 5px;border: 1px #eeeeee solid;margin-left: 0.5rem;" v-for="(item1,index1) in doctor_info_array.getDoctor.label" :key="index1">
                            {{ item1 }}
                        </div>
                    </div>
                    <div style="width: 100%;height: 60%;">
                        <div style="height: 1rem"></div>
                        <div style="margin-left: 1.5rem;font-size: 10px;padding: 1rem 0;" v-if="doctor_info_array.getDoctor.intro !== ''" v-html="doctor_info_array.getDoctor.intro"></div>
                        <div style="margin-left: 1.5rem;font-size: 10px;" v-else>
                            暂无简介
                        </div>
                    </div>

                    <div style="margin: 2rem;display: flex;align-items: center;justify-content: center;">
                        <span class="badge" @click="createOrder(1,doctor_info_array.id)" :class="{ 'gray': doctor_info_array.current_diagnose_num >= doctor_info_array.max_diagnose_num && doctor_info_array.user_is_appointment === 0 }" v-if="is_today === 1 && doctor_info_array.is_ol_diagnose === '1'">
                            <div>
                                <img src="@/assets/one.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div>在线问诊</div>
                        </span>
                        <span class="badge" @click="createOrder(2,doctor_info_array.id)" :class="{ 'gray': doctor_info_array.current_diagnose_num >= doctor_info_array.max_diagnose_num && doctor_info_array.user_is_appointment === 0 }" v-if="is_today === 1 && doctor_info_array.is_video_diagnose === '1'">
                            <div>
                                <img src="@/assets/two.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div>视频问诊</div>
                        </span>
                        <span class="badge" @click="createOrder(3,doctor_info_array.id)" :class="{ 'gray': doctor_info_array.current_diagnose_num >= doctor_info_array.max_diagnose_num || doctor_info_array.user_is_appointment === 1 }" v-if="doctor_info_array.is_appointment === '1'">
                            <div>
                                <img src="@/assets/treen.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div>预约</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="cll_con" v-if="doctor_info_type === 2 && is_doctorList === 2">
            <div class="title">
                <i class="el-icon-back" style="font-weight: bold;" @click="backDoctor"></i>
                <breadcrumb @home="show_home" :tabs="['医技', '医生详情']"></breadcrumb>
            </div>
            <div style="width: 95%;height: 80%;margin: 3% auto;display: flex;align-items: center;">
                <div style="width: 30%;height: 90%;border-radius: 12px;">
                    <img :src="doctor_info_arrays.pic" alt="Carousel Image" class="full-width-image" style="width: 100%;object-fit: cover;border-radius: 12px 12px 0 0;">
                </div>
                <div style="width: 70%;height: 90%;">
                    <div> <span style="font-size: 18px;color: #0e92dd;margin-left: 1.5rem;">{{ doctor_info_arrays.name }}</span> <span style="font-size: 13px;font-weight: bold;margin-left: 1.5rem;">{{ doctor_info_arrays.title }}</span> </div>
<!--                    <div style="display: flex;margin-top: 1rem;margin-left: 1rem;">-->
<!--                        <div style="">-->
<!--                          <span class="tag" v-for="item in doctor_info_arrays.label.split(',')" :key="index">{{item}}</span>-->
<!--&lt;!&ndash;                            {{ doctor_info_arrays.label }}&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
                    <div style="width: 100%;height: 60%;">
                        <div style="height: 1rem"></div>
                        <div style="margin-left: 1.5rem;font-size: 10px;padding: 1rem 0;" v-if="doctor_info_arrays.intro !== ''" v-html="doctor_info_arrays.intro"></div>
                        <div style="margin-left: 1.5rem;font-size: 10px;" v-else>
                            暂无简介
                        </div>
                    </div>

                    <div style="margin: 2rem;display: flex;align-items: center;justify-content: center;" v-if="doctor_info_arrays.is_today_schedules == 1">
                        <span class="badge" @click="createOrder(1,doctor_info_arrays.schedules[0].id,2)" v-if="doctor_info_arrays.schedules[0].is_ol_diagnose == '1'">
                            <div>
                                <img src="@/assets/one.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div>在线问诊</div>
                        </span>
                        <span class="badge" @click="createOrder(2,doctor_info_arrays.schedules[0].id,2)"  v-if="doctor_info_arrays.schedules[0].is_video_diagnose == '1'">
                            <div>
                                <img src="@/assets/two.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div>视频问诊</div>
                        </span>
                        <span class="badge" @click="createOrder(3,doctor_info_arrays.schedules[0].id,2)"  v-if="doctor_info_arrays.schedules[0].is_appointment == '1'">
                            <div>
                                <img src="@/assets/treen.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div>预约</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!--//修改身份证号码-->
        <el-dialog title="预约" :visible.sync="isOrder" width="30%" append-to-body>
            <div style="padding: 5%;display: flex;justify-content: space-evenly;">
                <el-select v-model="value1" placeholder="请选择" @change="change1">
                    <el-option
                        v-for="item in optionsDate"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-button size="small" style="width: 30%" type="primary" @click="reservation">预约</el-button>
            </div>
        </el-dialog>

        <!-- //问诊-->
        <el-dialog title="问诊" :visible.sync="dialogMaterial" width="30%">
            <user-im :order_states="order_states" :doctor_id="doctor_id" :order_id="order_id" :doctor_pic="doctor_pic" :user_pic="user_pic" v-if="dialogMaterial" ref="doctorim" @Consultation="Consultation"></user-im>
        </el-dialog>
<!--        <TUICallKit style="position: fixed;top: 50%;left: 50%; transform: translate(-50%, -50%); width: 50%;height: 50%;"/>-->

        <el-dialog title="视频通话" :visible.sync="testrtcType" width="50%" :close-on-press-escape="false" :close-on-click-modal="false">
          <ConferenceMainView></ConferenceMainView>
        </el-dialog>
    </div>
</template>
<script>
import {
    addressList, appointmentAdd,
    attachmentAdd, config,
    doctorInfo, getFamousDoctorsList,
    getList,
    getMenuList, MeetingRecords,
    orderAdd,
    orderEdit,
    orderShow, recordsAdd, recordsEdit,
    user,
    userEdit
} from "@/api";
import userIm from "@/components/userIm.vue";
import genTestUserSig from "@/debug/GenerateTestUserSig-es.js";
import { ConferenceMainView,conference } from '@tencentcloud/roomkit-web-vue2.7';

export default {
    name: 'medicalTechnology',
    components: {ConferenceMainView, userIm},
    props: {
        menu_id: Number,
    },
    data() {
        return {
          menuIndex: 1,
          menuLeftList: [
            {
              icon: "/img/pa4.png",
              name: "中医",
              activeIcon: "/img/pa7.png",
              target: "chinese_doctor"
            },
            {
              icon: "/img/pa2.png",
              name: "医技",
              activeIcon: "/img/pa5.png",
              target: "doctor_technology"
            },
            {
              icon: "/img/pa3.png",
              name: "妇幼",
              activeIcon: "/img/pa6.png",
            }
          ],
            is_doctorList: 2,
            testrtcType: false,
            user: 'doctor',
            doctor_id: '',
            doctor_pic: '',
            order_states: '',
            user_pic: '',
            userName: '',
            valueSex: '',
            userAge: '',
            textarea: '',
            uploadData: false,
            activeIndex: 'item-0',
            activeIndex2: '1',
            dialogMaterial: false,
            options: [{
                value: '选项1',
                label: '本地'
            }, {
                value: '选项2',
                label: '外地'
            }],
            value: '',
            value1: '',
            secondMenuList:[],
            doctorListType: 1,
            orderType: 1,
            department_name: '',
            weekArray: [],
            doctorList: [],
            optionsDate: [{
                value: '01:00:00',
                label: '1点'
            },{
                value: '01:30:00',
                label: '1点半'
            }, {
                value: '02:00:00',
                label: '2点'
            }, {
                value: '02:30:00',
                label: '2点半'
            }, {
                value: '03:00:00',
                label: '3点'
            }, {
                value: '03:30:00',
                label: '3点半'
            }, {
                value: '04:00:00',
                label: '4点'
            }, {
                value: '04:30:00',
                label: '4点半'
            }, {
                value: '05:00:00',
                label: '5点'
            }, {
                value: '05:30:00',
                label: '5点半'
            }, {
                value: '06:00:00',
                label: '6点'
            }, {
                value: '06:30:00',
                label: '6点半'
            }, {
                value: '07:00:00',
                label: '7点'
            }, {
                value: '07:30:00',
                label: '7点半'
            }, {
                value: '08:00:00',
                label: '8点'
            }, {
                value: '08:30:00',
                label: '8点半'
            }, {
                value: '09:00:00',
                label: '9点'
            }, {
                value: '09:30:00',
                label: '9点半'
            }, {
                value: '10:00:00',
                label: '10点'
            }, {
                value: '10:30:00',
                label: '10点半'
            }, {
                value: '11:00:00',
                label: '11点'
            }, {
                value: '11:30:00',
                label: '11点半'
            }, {
                value: '12:00:00',
                label: '12点'
            }, {
                value: '12:30:00',
                label: '12点半'
            }, {
                value: '13:00:00',
                label: '13点'
            }, {
                value: '13:30:00',
                label: '13点半'
            }, {
                value: '14:00:00',
                label: '14点'
            }, {
                value: '14:30:00',
                label: '14点半'
            }, {
                value: '15:00:00',
                label: '15点'
            }, {
                value: '15:30:00',
                label: '15点半'
            }, {
                value: '16:00:00',
                label: '16点'
            }, {
                value: '16:30:00',
                label: '16点半'
            }, {
                value: '17:00:00',
                label: '17点'
            }, {
                value: '17:30:00',
                label: '17点半'
            }, {
                value: '18:00:00',
                label: '18点'
            }, {
                value: '18:30:00',
                label: '18点半'
            }, {
                value: '19:00:00',
                label: '19点'
            }, {
                value: '19:30:00',
                label: '19点半'
            }, {
                value: '20:00:00',
                label: '20点'
            }, {
                value: '20:30:00',
                label: '20点半'
            }, {
                value: '21:00:00',
                label: '21点'
            }, {
                value: '21:30:00',
                label: '21点半'
            },{
                value: '22:00:00',
                label: '22点'
            },{
                value: '22:30:00',
                label: '22点半'
            }, {
                value: '23:00:00',
                label: '23点'
            }, {
                value: '23:30:00',
                label: '23点半'
            }, {
                value: '00:00:00',
                label: '0点'
            }, {
                value: '00:30:00',
                label: '0点半'
            }],
            isOrder: false,
            doctorName: [],
            doctorName1: [],
            schedules_id: '',
            start_time: '',
            user_info: [],
            dialogImageUrl: '',
            dialogVisible: false,
            token: '',
            upload_image: [],
            upload_image_text: [],
            order_id: '',
            optionsSex: [{
                value: '1',
                label: '男'
            },{
                value: '2',
                label: '女'
            }],
            user_id: '',
            is_today: 1,
            headline: '',
            SDKAppID: '',
            SecretKey: '',
            patient_id: '',
            call_type: 0,
            patient_name: '',
            user_name: '',
            en_user_name: '',
            en_doctor_name: '',
            user_role: '',
            MenuList_id: '',
            thirdMenuList: [],
            thirdMenuList_type: 1,
            doctor_info_type: 1,
            doctor_info_array: [],
            MenuListValue: '',
            FamousDoctorsArray: [],
            FamousDoctorsTotal: 0,
            doctor_info_arrays: [],
            is_status: 1,
        }
    },
    mounted() {
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id')
        this.user_role = localStorage.getItem('user_role');
        this.en_user_name = localStorage.getItem('en_user_name');
        this.secondaryMenu()
        this.generateWeekArray();
        this.userInfo()
        this.init()
        this.addList()
        // this.famous_doctor()
      this.default_doctor()
    },
    methods: {
        changeTarget(item, index) {
          this.menuIndex = index;
          // this.$emit("chioce", item.target)
          this.$emit('backPage', item.target);
        },
        show_home() {
            this.$emit('show-home')
        },
        default_doctor(){
            this.is_doctorList = 1
        },
        famous_doctor(){
            this.getFamousDoctorsListInfo()
            this.is_doctorList = 2
        },
        getFamousDoctorsListInfo(){
            getFamousDoctorsList({
                page: 1,
                limit: 10,
                medical_department_id: 2,
            }).then(res=>{
                if(res.data.code === 1){
                    this.FamousDoctorsArray = res.data.data.data
                    this.FamousDoctorsTotal = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        // 点击查看医生详情
        doctor_info(array){
            // if (array.getDoctor.label !== ''){
            //     array.getDoctor.label = array.getDoctor.label.split(',');
            // }
            console.log(array,99)
            this.is_doctorList = 1
            this.doctor_info_type = 2
            this.doctor_info_array = array
        },
        // 点击查看名医推荐
        doctor_infos(array){
            this.doctor_info_type = 2
            this.is_doctorList = 2
            this.doctor_info_arrays = array
            console.log(array)
        },
        //返回排版列表
        backDoctor(){
            this.doctor_info_type = 1
        },
        //选择二级分类
        MenuListChange(e){
            this.department_id = e
            this.MenuList_id = e
            getMenuList({
                pid: e,
            }).then(res=>{
                if(res.data.code === 1){
                    this.thirdMenuList = res.data.data
                    if (res.data.data.length > 0){
                        this.thirdMenuList_type = 2
                    }else {
                        this.thirdMenuList_type = 1
                        this.layoutList(this.weekArray[0].date)
                    }
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        thirdSelect(key){
            const id = key.split('-').pop()
            this.department_id = this.thirdMenuList[id].id
            this.layoutList(this.weekArray[0].date)
        },
        //回到上一页
        back(){
            this.$emit('backPage');
        },
        created() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(now.getDate()).padStart(2, '0');
            const hour = String(now.getHours()).padStart(2, '0');
            const minute = String(now.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hour}:${minute}`;
        },
        //通话之前执行
        beforeCalling(){
            const json_str = {"time":this.created(),'text':this.user_name+'发起'}
            const jsonStr = JSON.stringify(json_str);
            if(this.call_type === 1){
                orderEdit({
                    order_id: this.order_id,
                    user_id: this.user_id,
                    order_state: 2,
                    meetings_info: jsonStr,
                }).then(res => {
                    if(res.data.code === 1){
                        this.call_type = 0
                    }
                })
            }
        },
        decrypt(encodedStr){
            return atob(encodedStr);
        },
        //关闭问诊页面
        Consultation(){
            this.dialogMaterial = false
            this.layoutList(this.formattedDate)
        },
        handleSelect(key) {
            if(key === "item-0"){
                this.is_today = 1

            }else{
                this.is_today = 2
            }
            const parts = key.split('-');
            const index = parts.length > 1 ? parseInt(parts[1]) : null;
            this.queryDate = this.weekArray[index].date;
            this.formattedDate = this.weekArray[index].date;
            this.layoutList(this.queryDate)
        },
        //查看排版医生
        lookDoctor(item,items){
            this.headline = items.name
            this.department_id = item.id
            this.department_name = item.name
            this.doctorListType = 2
            this.addList()
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1; // 月份是从 0 到 11，所以要加 1
            const day = currentDate.getDate();
            this.formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
            this.layoutList(this.formattedDate)
        },
        addList(){
            addressList().then(res=>{
                this.options = res.data.data.list
            })
        },
        secondaryMenu(){
            getMenuList({
                pid: this.menu_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.secondMenuList = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        homePage(){
            this.doctorListType = 1
        },
        homePage1(){
            this.doctorListType = 2
        },
        //地区选择
        change(e){
            this.address_id = e
            this.layoutList(this.formattedDate)
        },
        //预约时间
        change1(e){
            this.address_id = e
            if(this.is_status === 2){
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1; // 月份是从 0 到 11，所以要加 1
                const day = currentDate.getDate();
                const formattedMonth = month.toString().padStart(2, '0');
                const formattedDay = day.toString().padStart(2, '0');
                this.formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
                this.start_time = this.formattedDate+' '+e
            }else{
                this.start_time = this.formattedDate+' '+e
            }
        },
        generateWeekArray() {
            const today = new Date();
            const weekArray = [];
            for (let i = 0; i < 7; i++) {
                const currentDate = new Date(today);
                currentDate.setDate(today.getDate() + i);
                const dayOfWeek = this.getDayOfWeek(currentDate.getDay());
                this.formattedDate = this.formatDate(currentDate);
                weekArray.push({
                    date: this.formattedDate,
                    dayOfWeek: dayOfWeek,
                });
            }
            this.weekArray = weekArray;
            console.log(this.weekArray,'日期')
        },
        getDayOfWeek(dayIndex) {
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
            return days[dayIndex];
        },
        formatDate(date) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedDate = date.toLocaleDateString('zh-CN', options);
            return formattedDate.replace(/\//g, '-');
        },
        //医生排班列表
        layoutList(formattedDate){
            getList({
                date: formattedDate,
                department_id: this.department_id,
                address_id: this.address_id,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.doctorList = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //预约
        createOrder(type,id,status){
            this.is_status = status
            this.schedules_id = id
            this.orderType = type
            if(type === 3){
                this.isOrder = true
                if (this.is_today == '1'){
                    const currentDate = new Date();
                    let hour = currentDate.getHours();
                    let minute = currentDate.getMinutes();
                    let hours = '';
                    if(minute != '0'){
                        hours = hour + 1;
                    }
                    this.optionsDate = this.optionsDate.filter(item => {
                        return parseInt(item.value.split(":")[0]) >= hours;
                    });
                }else{
                    this.optionsDate = [{
                        value: '01:00:00',
                        label: '1点'
                    },{
                        value: '01:30:00',
                        label: '1点半'
                    }, {
                        value: '02:00:00',
                        label: '2点'
                    }, {
                        value: '02:30:00',
                        label: '2点半'
                    }, {
                        value: '03:00:00',
                        label: '3点'
                    }, {
                        value: '03:30:00',
                        label: '3点半'
                    }, {
                        value: '04:00:00',
                        label: '4点'
                    }, {
                        value: '04:30:00',
                        label: '4点半'
                    }, {
                        value: '05:00:00',
                        label: '5点'
                    }, {
                        value: '05:30:00',
                        label: '5点半'
                    }, {
                        value: '06:00:00',
                        label: '6点'
                    }, {
                        value: '06:30:00',
                        label: '6点半'
                    }, {
                        value: '07:00:00',
                        label: '7点'
                    }, {
                        value: '07:30:00',
                        label: '7点半'
                    }, {
                        value: '08:00:00',
                        label: '8点'
                    }, {
                        value: '08:30:00',
                        label: '8点半'
                    }, {
                        value: '09:00:00',
                        label: '9点'
                    }, {
                        value: '09:30:00',
                        label: '9点半'
                    }, {
                        value: '10:00:00',
                        label: '10点'
                    }, {
                        value: '10:30:00',
                        label: '10点半'
                    }, {
                        value: '11:00:00',
                        label: '11点'
                    }, {
                        value: '11:30:00',
                        label: '11点半'
                    }, {
                        value: '12:00:00',
                        label: '12点'
                    }, {
                        value: '12:30:00',
                        label: '12点半'
                    }, {
                        value: '13:00:00',
                        label: '13点'
                    }, {
                        value: '13:30:00',
                        label: '13点半'
                    }, {
                        value: '14:00:00',
                        label: '14点'
                    }, {
                        value: '14:30:00',
                        label: '14点半'
                    }, {
                        value: '15:00:00',
                        label: '15点'
                    }, {
                        value: '15:30:00',
                        label: '15点半'
                    }, {
                        value: '16:00:00',
                        label: '16点'
                    }, {
                        value: '16:30:00',
                        label: '16点半'
                    }, {
                        value: '17:00:00',
                        label: '17点'
                    }, {
                        value: '17:30:00',
                        label: '17点半'
                    }, {
                        value: '18:00:00',
                        label: '18点'
                    }, {
                        value: '18:30:00',
                        label: '18点半'
                    }, {
                        value: '19:00:00',
                        label: '19点'
                    }, {
                        value: '19:30:00',
                        label: '19点半'
                    }, {
                        value: '20:00:00',
                        label: '20点'
                    }, {
                        value: '20:30:00',
                        label: '20点半'
                    }, {
                        value: '21:00:00',
                        label: '21点'
                    }, {
                        value: '21:30:00',
                        label: '21点半'
                    },{
                        value: '22:00:00',
                        label: '22点'
                    },{
                        value: '22:30:00',
                        label: '22点半'
                    }, {
                        value: '23:00:00',
                        label: '23点'
                    }, {
                        value: '23:30:00',
                        label: '23点半'
                    }, {
                        value: '00:00:00',
                        label: '0点'
                    }, {
                        value: '00:30:00',
                        label: '0点半'
                    }]
                }
            }else{
                orderAdd({
                    schedules_id: this.schedules_id,
                    order_type: type,
                }).then(res=>{
                    if(res.data.code !== 1){
                        this.$message({
                            message: '订单已创建去我的问诊中查看',
                            type: 'warning'
                        });
                    }else if(res.data.code == 401){
                        this.$emit('loginOuts');
                    }else{
                        this.order_id = res.data.data.order_id
                        orderShow({
                            order_id: res.data.data.order_id,
                            user_id: this.user_id,
                        }).then(res1=>{
                            if(res1.data.code !== 1){
                                this.$message({
                                    message: res1.data.msg,
                                    type: 'warning'
                                });
                            }else{
                                console.log(res1.data.data)
                                if(type === 2){
                                    // window.open(res1.data.data.meetings_info.meeting_info_list[0].join_url)
                                    this.patient_id = res1.data.data.doctor_id
                                    this.patient_name = res1.data.data.doctor.name
                                    this.user_name = res1.data.data.user.name
                                    this.call_type = 1
                                    this.en_doctor_name = res1.data.data.doctor.en_name
                                    this.$emit('call_assembly',2);
                                    // this.call()

                                    const attendee = res1.data.data.user.en_name+'_'+'user_'+res1.data.data.user.id.toString()
                                    const initiator = res1.data.data.doctor.en_name+'_'+'doctor_'+res1.data.data.doctor_id.toString()
                                    MeetingRecords({
                                        meeting_source: 1,
                                        organizer_id: this.user_id,
                                        role: 1,
                                        attendees_id: attendee+','+initiator,
                                        order_id: res.data.data.order_id,
                                    }).then(res2 => {
                                        localStorage.setItem('roomid', res1.data.data.id);
                                        localStorage.setItem('userId', attendee);
                                        localStorage.setItem('cache_ids', 'doctor'+res1.data.data.doctor_id);
                                        // this.testrtcType = true
                                        console.log(res2,222,res1)
                                        this.$emit('testrtcType_change', {
                                            video_room_type: 2,
                                            video_room_id: res2.data.data.id,
                                            video_room_name: res2.data.data.user_name,
                                        });

                                    })
                                  // this.roomlogin(res1.data.data.id)
                                }else{
                                    this.doctor_id = res1.data.data.doctor_id
                                    this.order_states = res1.data.data.order_state
                                    this.doctor_pic = res1.data.data.doctor.pic
                                    this.user_pic = res1.data.data.user.pic
                                    console.log(this.order_id,this.doctor_id,this.doctor_pic,this.user_pic,this.order_states,'订单信息')
                                    this.dialogMaterial = true
                                }
                            }
                        })
                    }
                })
            }
        },
      async roomlogin(room_id){
        await conference.join(String(room_id), {
          isOpenCamera: false,
          isOpenMicrophone: false,
        });
      },
        //初始化视频
        init() {
            config().then(res=>{
                if(res.data.code === 1){
                    this.SDKAppID = this.decrypt(res.data.data.sdk_app_id)
                    this.SecretKey = this.decrypt(res.data.data.sdk_secret_key)
                    try {
                        const {userSig} = genTestUserSig({
                            userID: this.en_user_name+'_'+this.user_role+'_'+this.user_id.toString(),
                            SDKAppID: Number(this.SDKAppID),
                            SecretKey: this.SecretKey,
                        });
                        // 初始化

                    } catch (error) {
                        // alert(`[TUICallKit] Initialization failed. Reason: ${error}`);
                        this.$message({
                            message: error,
                            type: 'warning'
                        });
                    }
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        async createGroupID() {
            const SDKAppID = this.SDKAppID
            // const tim = TIM.create({ SDKAppID });
            const memberList = [];
            this.userIDList.forEach((userID) => {
                memberList.push({
                    userID: userID
                });
            });
            const res = await tim.createGroup({
                // type: TIM.TYPES.GRP_PUBLIC, // 必须是 public 群
                name: 'WebSDK',
                memberList
            });
            return res.data.group.groupID;
        },
        //拨打
        async call() {
            try {
                this.videoMore = true
                const hasPermissions = await this.checkMediaPermissions();
                if (hasPermissions === '没有授权摄像头或者没连接设备') {
                    this.$message({
                        message: '没有授权摄像头或者没连接设备,请到设置-权限中授权',
                        type: 'warning'
                    });
                }else if (hasPermissions === '没有授权麦克风或者没连接设备') {
                    this.$message({
                        message: '没有授权麦克风或者没连接设备,请到设置-权限中授权',
                        type: 'warning'
                    });
                    return;
                }
                recordsAdd({
                    attendees_id: this.en_doctor_name+'_'+'doctor_'+this.patient_id.toString(),
                }).then(res=>{
                    if(res.data.code === 1){
                        this.userIDList = res.data.data.userIDList
                        const groupID = this.createGroupID();
                        // console.log(groupID)
                        groupID.then(result => {
                            recordsEdit({
                                id: res.data.data.id,
                                group_id: result,
                                meeting_source: 1,
                                organizer_id: this.user_id,
                                role: 1,
                            }).then(res=>{
                                if(res.data.code !== 1){
                                    this.$message({
                                        message: '网络错误',
                                        type: 'warning'
                                    });
                                }
                            })
                            orderEdit({
                                meeting_records_id: res.data.data.id,
                                order_id: this.order_id,
                                user_id: this.user_id,
                                order_state: 2,
                            }).then(res => {
                                if (res.data.code !== 1) {
                                    this.$message({
                                        message: '网络错误',
                                        type: 'success'
                                    });
                                }
                            })
                            const callOptions = {
                                userIDList: this.userIDList,
                                groupID: result,
                                // type: TUICallType.VIDEO_CALL,
                                roomID: res.data.data.roomID,
                                timeout: res.data.data.timeout,
                            };

                        }).catch(error => {
                            console.error(error);
                        });
                    }else{
                        this.$message({
                            message: '网络错误',
                            type: 'warning'
                        });
                    }
                })
            } catch (error) {
                this.videoMore = false
            }
        },
        async checkMediaPermissions() {
            try {
                const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
                videoStream.getTracks().forEach(track => track.stop());
            } catch (videoError) {
                return '没有授权摄像头或者没连接设备'
            }
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                stream.getTracks().forEach(track => track.stop());
                return true;
            } catch (error) {
                return '没有授权麦克风或者没连接设备'
            }
        },
        //确定预约
        reservation(){
            this.isOrder = false
            appointmentAdd({
                user_id: this.user_id,
                schedules_id: this.schedules_id,
                start_time: this.start_time,
            }).then(res=>{
                if(res.data.code === 1){
                    this.isOrder = false
                    this.$message({
                        message: '创建成功去我的问诊查看',
                        type: 'success'
                    });
                    this.order_id = res.data.data.order_id
                    // this.uploadData = true
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.isOrder = false
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
            this.userInfo()
        },
        lookInfo(name){
            this.doctorName1 = name
            doctorInfo({
                id: name.getDoctor.id
            }).then(res=>{
                if(res.data.code === 1){
                    this.doctorName = res.data.data

                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
            this.doctorListType = 3
        },
        //查询患者信息
        userInfo(){
            user().then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                    this.userName = res.data.data.name
                    this.valueSex = res.data.data.sex
                    this.userAge = res.data.data.age
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //图片上传
        handleRemove(file, fileList) {
            console.log(file, fileList ,'删除');
            this.upload_image = this.upload_image.filter(fileName => fileName !== file.response.data.file_path);
            this.upload_image_text = this.upload_image_text.filter(fileName => fileName !== file.response.data.name);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            console.log(file.url,'文件路径')
            this.dialogVisible = true;
        },
        handleSuccess(e){
            this.upload_image.push(e.data['file_path'])
            this.upload_image_text.push(e.data['name'])
        },
        beforeUpload(file) {
            // 检查文件类型
            const isImage = file.type.startsWith('image/');
            // const isWord = file.name.endsWith('.doc') || file.name.endsWith('.docx') || file.name.endsWith('.xlsx') || file.name.endsWith('.pdf');
            if (!isImage) {
                // if (!isWord) {
                this.$message.error('只能上传图片文件');
                return false;
            }
            const maxSize = 2 * 1024 * 1024; // 2Mb
            if (file.size > maxSize) {
                this.$message.error('文件大小不能超过2Mb');
                return false;
            }
            return true;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        //完成
        button(){
            // orderAdd({
            //     schedules_id: this.schedules_id,
            //     start_time: this.start_time,
            //     order_type: this.orderType,
            // }).then(res=>{
            //     if(res.data.code === 1){
            //         this.isOrder = false
            //         this.$message({
            //             message: '创建成功去我的问诊查看',
            //             type: 'success'
            //         });
            //         this.order_id = res.data.data.order_id
            //         this.uploadData = true
            //     }else{
            //         this.isOrder = false
            //         this.$message({
            //             message: res.data.msg,
            //             type: 'warning'
            //         });
            //     }
            // })
            userEdit({
                name: this.userName,
                sex: this.valueSex,
                age: this.userAge,
                user_id: this.user_id,
            }).then(res=>{
                if(res.data.code !== 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    orderEdit({
                        order_id: this.order_id,
                        user_id: this.user_id,
                        case_description: this.textarea,
                        doctor_remark: '',
                        order_type: this.orderType,
                    }).then(res=>{
                        if(res.data.code !== 1){
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }else{
                            for (let i = 0; i < this.upload_image.length; i++) {
                                attachmentAdd({
                                    order_id: this.order_id,
                                    file_url: this.upload_image[i],
                                    file_name: this.upload_image_text[i],
                                    user_id: this.user_id,
                                }).then(res=>{
                                    if(res.data.code !== 1){
                                        this.$message({
                                            message: res.data.msg,
                                            type: 'warning'
                                        });
                                    }else{
                                        this.$message({
                                            message: '提交成功',
                                            type: 'success'
                                        });
                                        this.uploadData = false
                                    }
                                })
                            }
                        }
                    })
                }
            })
        },
        wssetdata(e) {
            if (this.$refs.doctorim) {
                this.$refs.doctorim.wssetdata(e)
            }
        }
    }
}
</script>
<style>
.cll_con {
  z-index: 1;
}
.cll_con .el-input__inner{
    background-color: #eeeeee;
}
.cll_con .el-menu-demo{
    border-bottom: none !important;
}
</style>
<style scoped>
div::-webkit-scrollbar {
    width: 12px;
}
div::-webkit-scrollbar-thumb {
    background-color: #888;
}
div {
    scrollbar-width: thin;
}
.con{
  position: relative;
  height: 95%;
  width: 95vw;
  //background-color: #f7f7f7;
  //border: 1px #d6d8dc solid;
  //border-radius: 12px 12px 0 0;
  display: flex;
  //width: 90%;
  //height: 90%;
  //margin: 3% auto;
  //background-color: #ffffff;
  //border: 1px #bbbbbb solid;
}
.title{
    width: 97%;
    height: 10%;
    background-color: #0e92dd;
    font-size: 17px;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 3%;
    border-radius: 12px 12px 0 0;
}
.listInfo{
    width: 90%;
    height: 75%;
    margin: 1% auto;
    display: flex;
    justify-content: space-between;
}
.doctor{
    display:flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px #dddddd solid;
    padding: 2%;
    cursor: pointer;
}
.project{
    width: 90%;
    font-size: 15px;
    margin-top: 2%;
    border-left: 2px #0e92dd solid;
    margin-left: 2%;
    text-align: left;
    padding-left: 3%;
}
.header-container {
    width: 100%;
    height: 3.5rem; /* 使用 rem 单位，根据需要调整 */
    border-bottom: 1px #dddddd solid;
    padding-top: 10px;
    display: flex;
}

.el-menu-demo {
    //display: flex;
    width: 100%;
}

.menu-item {
    width: 12%;
    padding: 0.5rem;
    text-align: center;
}
.doctor-item {
    width: 85%;
    height: 20%;
    margin: 1.6rem auto 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1%;
}

.avatar-container {
    //width: 20%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.info-container {
    width: 80%;
}

.info-header {
    width: 100%;
    white-space: nowrap; /* 避免换行 */
}

.name {
    font-size: 16px;
    margin-left: 2%;
}

.badge {
    margin-left: 2%;
    background-color: #0e92dd;
    border-radius: 3px;
    padding: 2px 5px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: right;
}

.gray{
    background-color: gray;
    pointer-events: none;
}

.ellipsis {
    //white-space: nowrap; /* 避免换行 */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.cll_con{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}
.con{
  position: relative;
  height: 95%;
  width: 60%;
  //background-color: #f7f7f7;
  //border: 1px #d6d8dc solid;
  //border-radius: 12px 12px 0 0;
  display: flex;
  //width: 90%;
  //height: 90%;
  //margin: 3% auto;
  //background-color: #ffffff;
  //border: 1px #bbbbbb solid;
}
.title{
  border-radius: 12px 12px 0 0;
  width: 97%;
  height: 10%;
  background-color: #0e92dd;
  font-size: 17px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 3%;
}
.listInfo{
  width: 90%;
  height: 10%;
  margin: 1% auto;
  display: flex;
  justify-content: space-between;
}
.doctor{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: left;
  border-bottom: 1px #dddddd solid;
  padding: 2%;
  cursor: pointer;
}
.project{
  width: 90%;
  font-size: 15px;
  margin-top: 2%;
  border-left: 2px #0e92dd solid;
  margin-left: 2%;
  text-align: left;
  padding-left: 3%;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.header-container {
  width: 100%;
  height: 3.5rem; /* 使用 rem 单位，根据需要调整 */
  border-bottom: 1px #dddddd solid;
  padding-top: 10px;
  display: flex;
}

.el-menu-demo {
  display: flex;
  width: 100%;
}

.menu-item {
  width: 12%;
  padding: 0.5rem;
  text-align: center;
}
.doctor-item {
  width: 85%;
  height: 20%;
  margin: 1.6rem auto 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1%;
}

.avatar-container {
  //width: 20%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.info-container {
  width: 80%;
}

.info-header {
  width: 100%;
  white-space: nowrap; /* 避免换行 */
}

.name {
  font-size: 16px;
  margin-left: 2%;
}

.badge {
  margin-left: 2%;
  background-color: #0e92dd;
  border-radius: 3px;
  padding: 2px 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: right;
}

.gray{
  background-color: gray;
  pointer-events: none;
}

.cll_con{
  width: calc(100% - 210px);
  margin-left: 10px;
  height: 100%;
  background-color: #FFFFFF;
}
.cll-left {
  position: relative;
  padding-top: 40px;
  box-sizing: border-box;
  width: 200px;
  height: 100%;
  background: #F0F9FF;

  .left-item {
    color: #017ed8;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    .left-img {
      width: 87px;
      height: 87px;
      box-sizing: border-box;
      background: #D5E7F3;
      border-radius: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 48px;
        height: 48px;

      }
    }
  }
  .left-img.active {
    background: #FFFFFF;
    border: 2px solid #017ED8;
  }
}
.doc-item {
  border: 2px solid #017ED8;
  box-sizing: border-box;
    height: 60%;
}
.tag {
  margin-right: 10px;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  border: 2px solid #efefef;
  color: #aaa;
  font-weight: 500;
}
</style>
