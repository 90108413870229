import {orderShow} from "@/api";
// ws.js
export default {
    wssetdata(e) {
        if (e.id) {
            orderShow({
                order_id: e.order_id
            }).then(res => {
                if (res.data.code === 1) {
                    if (e.role === 'doctor') {
                        console.log('医生发', res.data.data.user_id, this.user_id, this.user_role, Number(res.data.data.user_id) === Number(this.user_id) && this.user_role === 'user')
                        if (Number(res.data.data.user_id) === Number(this.user_id) && this.user_role === 'user') {
                            console.log('医生发1')
                            this.$message({
                                message: '收到' + res.data.data.doctor.name + '发来的消息,请到我的问诊中查看',
                                type: 'success'
                            });
                        }
                    } else {
                        console.log('患者发', res.data.data.user_id, this.user_id, this.user_role)
                        if (Number(res.data.data.doctor_id) === Number(this.user_id) && this.user_role === 'doctor') {
                            console.log('患者发1')
                            this.$message({
                                message: '收到' + res.data.data.user.name + '发来的消息,请到我的问诊中查看',
                                type: 'success'
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        }
        this.$refs.consultMater.wssetdata(e)
    },
    groupdata(e) {
        this.$refs.groupChat.groupdata(e)
    },
    videodata(e) {
        this.$refs.videoConf.videodata(e)
    },
    close_tape() {
        this.$refs.groupChat.close_tape()
    },
    send_tape() {
        this.$refs.groupChat.send_tape()
    },
    update_userlist(e) {
        if (e.msg_type == 'group_kicking' && e.sender_im_id == localStorage.getItem('video_im_id')) {
            console.log('我是被踢的人', e)
            this.$refs.groupChat.is_Group = 1
            this.$refs.groupChat.Group_init()
            this.$message({
                message: '您已被管理员移出群聊,请重新加入',
                type: 'success'
            });
        }
        this.$refs.groupChat.Group_members(e.group_id)
    },
};

