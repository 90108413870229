<template>
    <div class="con">
        <div style="margin-top: 2rem;">
            <span style="padding: 5%;font-size: 20px;font-weight: 400;border-bottom: 2px #0e92dd solid">账号信息</span>
        </div>
        <div style="margin:auto;width: 90%;display: flex;justify-content: space-between;align-items: center;border-bottom: 2px #dddddd solid;">
            <div style="padding: 10px;width: 20rem;font-size: 1.5rem;margin-top: 1rem;display: flex;justify-content: space-between;">
                <div>
                    <i class="el-icon-user" style="font-size: 1rem;"></i>
                    <span style="font-size: 1rem; margin-left: 0.5rem;">姓名: {{ user_info.name }}</span>
                </div>
            </div>
        </div>
        <div style="margin:auto;width: 90%;display: flex;justify-content: space-between;align-items: center;border-bottom: 2px #dddddd solid;">
            <div style="padding: 10px;width: 20rem;font-size: 1.5rem;margin-top: 1rem;display: flex;justify-content: space-between;">
                <div>
                    <i class="el-icon-phone-outline" style="font-size: 1rem;"></i>
                    <span style="font-size: 1rem; margin-left: 0.5rem;">电话: {{ user_info.tel }}</span>
                </div>
                <div style="color: #0e92dd;font-size: 1rem;cursor: pointer;" @click="isUpdateMobile = true">
                    <i class="el-icon-edit"></i>
                </div>
            </div>
        </div>

<!--        <div style="display: flex;align-items: center;justify-content: space-between">-->
<!--            <el-upload-->
<!--                style="text-align: left;padding: 0.5rem;"-->
<!--                class="upload-demo"-->
<!--                action="https://myg.zeyan.wang/index.php/api/myg/common/uploadFile"-->
<!--                :on-success="handleSuccess"-->
<!--                :before-upload="beforeUpload"-->
<!--                :headers="{ token: this.token }"-->
<!--                multiple-->
<!--                :show-file-list="false"-->
<!--                :limit="9">-->
<!--                <span>上传附件资料</span>-->
<!--            </el-upload>-->
<!--            <span style="padding: 0.5rem;cursor: pointer;" @click="download_attachments">下载附件资料</span>-->
<!--        </div>-->

        <el-button class="custom-button" style="margin-top: 0.5rem;" type="primary" @click="isUpdatePass = true">修改密码</el-button>
<!--        <el-button class="custom-button" type="primary" @click="my_profile">我的资料</el-button>-->
        <el-button class="custom-button" type="primary" @click="workload">工作量统计</el-button>
        <el-button class="custom-button" type="primary" @click="logout">退出登录</el-button>

        <!--//修改手机号码-->
        <el-dialog title="修改手机号码" :visible.sync="isUpdateMobile" width="30%" append-to-body>
            <el-form ref="form">
                <div>
                    <el-form-item class="no-border">
                        <el-input v-model="tel" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
<!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
<!--                        <div style="display: flex;justify-content: center">-->
<!--                            <el-input v-model="code" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;"></el-input>-->
<!--                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;" type="primary" @click="startCountdown(1)">{{ codeText }}</el-button>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
                </div>
                <el-form-item style="margin: 5% 0;">
                    <el-button style="width: 60%;font-weight: bold" type="primary" @click="updatePhone">确定修改</el-button>
                </el-form-item>
            </el-form>
            <div style="width: 100%;height: 20px;"></div>
        </el-dialog>

        <!--//修改密码-->
        <el-dialog title="修改密码" :visible.sync="isUpdatePass" width="30%" append-to-body>
            <el-form ref="form">
                <div>
                    <el-form-item class="no-border">
                        <el-input v-model="tels" placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
<!--                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">-->
<!--                        <div style="display: flex;justify-content: center">-->
<!--                            <el-input v-model="codes" placeholder="请输入验证码" prefix-icon="el-icon-mobile" style="width: 45%; border: none;"></el-input>-->
<!--                            <el-button style="width: 30%;font-weight: bold;padding: 5px 10px;font-size: 1.2rem" type="primary" @click="startCountdown(2)">{{ codeText }}</el-button>-->
<!--                        </div>-->
<!--                    </el-form-item>-->
                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">
                        <el-input v-model="id_number" placeholder="请输入身份证号" prefix-icon="el-icon-user" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
                    <el-form-item style="margin: 10% 0 0 0;border: none; padding: 0;">
                        <el-input v-model="passWord" placeholder="请输入密码" :show-password="true" prefix-icon="el-icon-lock" style="width: 75%; border: none;"></el-input>
                    </el-form-item>
                </div>
                <el-form-item style="margin: 5% 0;">
                    <el-button style="width: 60%;font-weight: bold" type="primary" @click="updatePwd">确定修改</el-button>
                </el-form-item>
            </el-form>
            <div style="width: 100%;height: 20px;"></div>
        </el-dialog>

        <!--//下载附件-->
<!--        <el-dialog title="下载附件" :visible.sync="downloadAttachments" width="30%">-->
<!--            （视频、图片、音频需打开后点击鼠标右键另存为）-->
<!--            <div style="width: 100%;height: 500px;text-align: left;padding: 1rem 0 0 1rem;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;">-->
<!--                <div v-for="(item_list,index_list) in doctorAttachment_list" :key="index_list" style="margin-top: 1.5rem;">-->
<!--&lt;!&ndash;                    <span style="cursor: pointer; color: #0e92dd;" @click="down_file(item_list.file_url,item_list.file_name)">{{ item_list.file_name }}</span>&ndash;&gt;-->
<!--                    <a style="text-decoration: none; color: #0e92dd;" :href="item_list.file_url" :download="item_list.file_name" target="_blank">{{item_list.file_name}}</a>-->
<!--                </div>-->
<!--                <div  style="display: flex;justify-content: center;margin-top: 2rem;">-->
<!--                    <el-pagination-->
<!--                        :page-size="10"-->
<!--                        :pager-count="5"-->
<!--                        layout="prev, pager, next"-->
<!--                        :total="list_num"-->
<!--                        @current-change="current_change">-->
<!--                    </el-pagination>-->
<!--                </div>-->

<!--&lt;!&ndash;                <a style="text-decoration: none; color: #0e92dd;" :href="item_list.file_url" :download="item_list.file_name" target="_blank">{{item_list.file_name}}</a>&ndash;&gt;-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>
</template>
<script>
import {
    doctorAttachment,
    doctorAttachmentAdd,
    doctorInfo,
    editTel,
    loginOut,
    resetPassword,
    sendSmS
} from "@/api";

export default {
    name: 'userInfo',
    data() {
        return {
            user_id: '',
            user_info: [],
            isUpdateMobile: false,
            isUpdatePass: false,
            tel: '',
            code: '',
            tels: '',
            codes: '',
            passWord: '',
            id_number: '',
            codeText: '获取验证码',
            codeButType: 1,
            count: 60,
            token: '',
            downloadAttachments: false,
            doctorAttachment_list: [],
            list_num: '',
            page: 1,
        }
    },
    mounted(){
        this.user_id = localStorage.getItem('user_id');
        this.token = localStorage.getItem('token')
        this.userInfo()
    },
    methods: {
        current_change(e){
            console.log(e,'用户点击了')
            this.page = e
            this.download_attachments()
        },
        download_attachments(){
            doctorAttachment({
                page: this.page,
                limit: 10,
                doctor_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.doctorAttachment_list = res.data.data.data
                    this.list_num = res.data.data.total
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
            this.downloadAttachments = true
        },
        //获取验证码
        startCountdown(type){
            if(this.codeButType === 1){
                this.codeButType = 2
                let phone = ''
                if (type === 1){
                    phone = this.tel
                }else{
                    phone = this.tels
                }
                sendSmS({
                    tel:phone
                }).then(res=>{
                    if(res.data.code === 1){
                        this.timer = setInterval(() => {
                            this.count--;
                            if (this.count <= 0) {
                                clearInterval(this.timer);
                                this.timer = null;
                                this.count = 60;
                                this.codeText = '获取验证码';
                                this.codeButType = 1
                            } else {
                                this.codeText = `${this.count} 秒后重发`;
                            }
                        }, 1000);
                    }else if(res.data.code == 401){
                        this.$emit('loginOuts');
                    }else{
                        this.codeButType = 1
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        //修改手机号码
        updatePhone(){
            editTel({
                tel: this.tel,
                code: this.code
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.userInfo()
                    this.isUpdateMobile = false
                    this.code = ''
                    this.tel = ''
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        userInfo(){
            doctorInfo({
                id: localStorage.getItem('user_id'),
            }).then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }
            })
        },
        workload(){
            this.$emit('workload');
        },
        my_profile(){
            this.$emit('myProfile');
        },
        uploadFiles(){

        },
        updatePwd(){
            resetPassword({
                tel: this.tels,
                id_number: this.id_number,
                new_password: this.passWord,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$emit('loginOut');
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('user_role');
                    localStorage.removeItem(this.tels);
                    localStorage.removeItem(this.tels+'pass');
                    this.$message({
                        message: '重置成功',
                        type: 'success'
                    });
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        logout(){
            loginOut({
                role: 'doctor',
            }).then(res=>{
                console.log(res.data,888)
                if(res.data.code === 1){
                    this.$emit('loginOut');
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('user_role');
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>
<style scoped>
.con{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 1px #bbbbbb solid;
    text-align: center;
}
.custom-button {
    width: 60%;
    height: 2rem; /* 使用 rem 单位，根据需要调整 */
    font-weight: bold;
    padding: 0;
    font-size: 1rem;
    margin: 0.3rem 0; /* 使用 rem 单位，根据需要调整 */
}
.upload_style{
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;

}
</style>
