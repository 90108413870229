<template>
    <div class="wrapper">
<!--        <div class="list_name" style="overflow-y: auto">-->
<!--            <el-menu style="width: 100%;" @select="switchOut" background-color="#f0f4f7" default-active="1">-->
<!--                <el-menu-item index="1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">医生端</el-menu-item>-->
<!--&lt;!&ndash;                <el-menu-item index="2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">患者端</el-menu-item>&ndash;&gt;-->
<!--            </el-menu>-->
<!--            <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain" alt="">-->
<!--        </div>-->

        <div class="content">
            <div style="height: 8%;display: flex;">
                <div style="padding: 2%;">
                    <el-select v-model="value" placeholder="请选择" style="width: 100px;" size="mini" @change="change">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div style="padding: 2%;">
                    <el-select v-model="value1" placeholder="请选择" style="width: 100px;" size="mini" @change="change1">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div style="width: 100%;height:83%;margin-top:2rem;overflow-y: auto;">
                <div style="position: relative;margin-top: 2rem;" v-for="(item, index) in orderList" :key="index">
                    <!--                <div style="width: 65px;height: 20px;background-color: #3894ff;border-radius: 3px;text-align: center;line-height: 20px;color: #ffffff;position: absolute;top: -30%;left: 4rem;padding: 5px">-->
                    <!--                    {{ item.order_type === '1' ? '在线问诊' : item.order_type === '2' ? '视频问诊' : '预约' }}-->
                    <!--                </div>-->
                    <!--                <div :style="{ width: '16rem', height: '20px', backgroundColor: item.order_state === '4' || item.order_state === '5' ? '#237903' : '#3894ff', borderRadius: '3px', textAlign: 'center', lineHeight: '20px', color: '#ffffff', position: 'absolute', top: '-30%', left: '10rem',padding: '5px' }">-->
                    <!--                    {{-->
                    <!--                    item.order_state === "1" ? '未开始' : item.order_state === "2" ? '进行中' : item.order_state === "3" ? '挂起' : item.order_state === "4" ? '用户自己取消' : item.order_state === "5" ? '已结束' : ''-->
                    <!--                    }}:-->
                    <!--                    {{ item.start_time }}-->
                    <!--                </div>-->

                    <!--          <div style="width: 65px;height: 20px;background-color: #3894ff;border-radius: 3px;text-align: center;line-height: 20px;color: #ffffff;position: absolute;top: -20%;left: 30rem;cursor: pointer" v-if="item.order_type==='2' && item.order_state === '2'" @click="end_order(item)">-->
                    <!--            结束订单-->
                    <!--          </div>-->

                    <div class="text-container">
                        <div
                            style="width: 70%;margin-top: 0.5rem;display: flex;align-items: center;justify-content: space-between">
                            <span class="text-content" v-if="item.user.length !== 0">
                                {{ item.user.name }} - {{ item.user.tel }} - {{ maskedIdNumber(item.user.id_number) }}
                            </span>
                            <el-tag size="small">{{ item.order_type === '1' ? '在线问诊' : item.order_type === '2' ? '视频问诊'
                                : '预约' }}</el-tag>
                            <el-tooltip
                                :content="`${item.order_state === '1' ? '未开始' : item.order_state === '2' ? '进行中' : item.order_state === '3' ? '挂起' : item.order_state === '4' ? '用户自己取消' : item.order_state === '5' ? '已结束' : ''}:${item.start_time}`"
                                placement="top">
                                <i class="el-icon-time" style="margin-right: 10px"></i>
                            </el-tooltip>

                        </div>
                        <div style="width: 1px;height: 30px;background-color: #ccc;margin-top: 7px;"></div>
                        <div
                            style="width: 30%;display: flex;align-items: center;justify-content: center;padding: 0.5rem 0">
                            <div>
                                <div style="font-size: 0.9rem;width: 4rem;height: 26px;background-color: #3894ff;border-radius: 6px;text-align: center;line-height: 26px;color: #ffffff;margin: 5px 5px 0 5px;cursor: pointer;"
                                    @click="detailInfo(item.order_state, item.user, item.case_description, item.id, item.doctor_remark, item)"
                                    v-if="item.order_type === '1' || item.order_type === '2'">
                                    <div>查看</div>
                                    <!--                                <div>详情</div>-->
                                </div>
                            </div>
                            <div
                                v-if="item.order_state === '1' && (item.order_type === '1' || item.order_type === '2')">
                                <div style="font-size: 0.9rem;width: 5rem;height: 26px;background-color: #3894ff;border-radius: 6px;text-align: center;line-height: 26px;color: #ffffff;margin: 5px 5px 0 5px;cursor: pointer;"
                                    @click="treatment(item.id, item.user.id)" v-if="item.order_type === '2'">
                                    现在接诊
                                </div>
                                <div style="font-size: 0.9rem;width: 5rem;height: 26px;background-color: #3894ff;border-radius: 6px;text-align: center;line-height: 26px;color: #ffffff;margin: 5px 5px 0 5px;cursor: pointer;"
                                    @click="chatTreatment(item.id, item.user.id)" v-else>
                                    现在接诊
                                </div>
                            </div>

                            <div
                                v-if="item.order_state !== '1' && (item.order_type === '1' || item.order_type === '2')">
                                <div style="font-size: 0.9rem;width: 4rem;height: 26px;background-color: #3894ff;border-radius: 6px;text-align: center;line-height: 26px;color: #ffffff;margin: 5px 5px 0 5px;cursor: pointer;"
                                    @click="videoDetails(item)"
                                    v-if="item.order_type === '2' && item.order_state === '2'">
                                    <!--                                问诊详情-->
                                    <div>问诊</div>
                                </div>
                                <div style="font-size: 0.9rem;width: 4rem;height: 26px;background-color: #3894ff;border-radius: 6px;text-align: center;line-height: 26px;color: #ffffff;margin: 5px 5px 0 5px;cursor: pointer;"
                                    @click="consultationDetails(item.user, item)" v-if="item.order_type === '1'">
                                    <!--                                问诊详情-->
                                    <div>问诊</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--        <el-dialog title="" :visible.sync="dialogMaterial" :width="dialogWidth">-->
            <el-dialog title="患者资料" append-to-body :visible.sync="dialogMaterial" width="50%">
                <div style="display: flex;font-size: 1.2rem;padding-bottom: 1rem;cursor: pointer">
                    <div style="margin-left: 2rem;color: #3894ff;z-index: 999">患者资料</div>
                    <div style="padding-left:1rem;z-index: 999" @click="consultationDetails(item_id, items)">在线问诊</div>
                </div>
                <!--            <div id="info" style="width: 100%;height: 30px;">-->
                <!--                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">-->
                <!--                    <el-menu-item index="1">患者资料</el-menu-item>-->
                <!--                    <el-menu-item index="2">在线问诊</el-menu-item>-->
                <!--                </el-menu>-->
                <!--            </div>-->
                <div style="width: 100%;height: 30px;"></div>
                <!--            <div style="width: 100%;height: 500px;display: flex;justify-content: space-between;" v-if="dialog_width === 1">-->
                <div style="width: 100%;height: 500px;display: flex;justify-content: space-between;">
                    <div style="width: 49%;height: 100%;margin-left: 10px;">
                        <div
                            style="width: 100%;height: 32%;border: 1px #000000 solid;border-radius: 10px;overflow-y: auto">
                            <div
                                style="width: 95%;height: 30%;margin: auto;border-bottom: 1px #dddddd solid;display: flex;justify-content: left;align-items: center;">
                                <div style="width: 22%;padding: 2%;margin: 0 2%;border: 1px #dddddd solid;">
                                    {{ patient_name }}
                                </div>
                                <div style="width: 10%;padding: 2%;margin: 0 2%;border: 1px #dddddd solid;">
                                    {{ patient_sex === "1" ? '男' : '女' }}
                                </div>
                                <div style="width: 22%;padding: 2%;margin: 0 2%;border: 1px #dddddd solid;">
                                    {{ patient_age }}
                                </div>
                            </div>
                            <div style="margin-top: 1rem;padding: 0 1.25rem">
                                <div v-for="(v, k) of items.info" :key="k" style="margin-bottom: 0.3125rem;">
                                    <p style="font-size: 0.875rem;margin: 0;">{{ v.name }}</p>
                                    <div v-for="(val, key) of v.children" :key="key" style="margin-left: 1rem;">
                                        <p style="font-size: 0.875rem">{{ val.label }}：{{ val.value }}</p>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 92%;height: 50%;margin: auto;padding: 3%;overflow-y: auto;">
                                {{ patient_case_description }}
                            </div>


                        </div>
                        <div style="width: 100%;height: 40%;">
                            <div
                                style="width: 100%;height: 50%;display: flex;justify-content: space-evenly;align-items: center;">
                                <div style="width: 100px;height: 100px;margin-top: 5px"
                                    v-for="(item1, index1) in imageList.slice(0, 2)" :key="index1">
                                    <el-image style="width: 90%;height: 90%;" :src="item1.file_url"
                                        :preview-src-list="[item1.file_url]">
                                    </el-image>
                                </div>
                                <div style="width: 26%;height: 85%;border: 1px #dddddd solid;display: flex;align-items: center;flex-wrap: wrap;justify-content: center;"
                                    v-if="imageList.length >= 3">
                                    <div style="font-size:15px;font-weight: bolder;width: 100%;text-align: center;"
                                        @click="look_more">查看更多
                                    </div>
                                    <div @click="look_more"><i class="el-icon-plus"
                                            style="font-size:40px;font-weight: bolder;"></i></div>
                                </div>
                            </div>
                            <div style="width: 100%;height: 45%;border: 1px #dddddd solid;border-radius: 10px;">
                                <div style="height: 19%;padding: 1%;">
                                    <el-input type="textarea" placeholder="备注" v-model="textarea"
                                        :style="{ width: '100%', fontSize: '1rem' }" resize="none" class="no-border"
                                        :rows="1.5">
                                    </el-input>
                                </div>
                                <div
                                    style="height: 50%;overflow-y: auto;padding: 1%;display: flex;justify-content: center;margin-top: 2rem;">
                                    <el-button size="small" style="padding: 5px;height: 60%;" type="primary"
                                        @click="updateNotes">修改备注
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;height: 26%;overflow-y: auto;">
                            <el-upload class="upload-demo" :action="Upload_Api_urls" :on-preview="handlePreview"
                                :on-remove="handleRemove" :on-success="handleSuccess" :before-upload="beforeUpload"
                                multiple :limit="3" :headers="{ token: this.token }" :on-exceed="handleExceed"
                                :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip"></div>
                            </el-upload>
                        </div>
                    </div>
                    <div
                        style="width: 49%;height: 98%;border: 1px #dddddd solid;border-radius: 10px;overflow-y: auto;margin: 0 1rem;">
                        <div style="padding: 2% 3%;">历史诊疗信息</div>
                        <div style="text-align: center;margin: 2%;display: flex;align-items: center;"
                            v-for="(item, index) in orderList1" :key="index">
                            <span>{{ index + 1 }}. {{ item.start_time }}</span>
                            <!--                        <span style="width: 12rem;display: inline-block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0 1rem"> {{ item.user.name }} </span>-->
                            <span
                                style="width: 12rem;display: inline-block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0 1rem">
                                {{
                                    item.doctor.name
                                }} </span>
                            <!--                        <span style="width: 12rem;display: inline-block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0 1rem"> {{ item.doctor.tel }} </span>-->
                            <el-button size="small" style="padding: 5px;" type="primary"
                                @click="historicalDetails(item.id)">详情
                            </el-button>
                        </div>
                    </div>
                </div>
                <!--            <div v-else>-->
                <!--                <div style="width: 90%;height: 300px;margin: auto;border: 1px #dddddd solid;border-radius: 10px;">-->
                <!--                    123-->
                <!--                </div>-->
                <!--                <div style="width: 90%;height: 150px;margin: auto;display: flex;justify-content: center;align-items: center;">-->
                <!--                    <el-button size="small" type="primary">暂时挂起</el-button>-->
                <!--                    <el-button size="small" type="primary">问诊结束</el-button>-->
                <!--                </div>-->
                <!--            </div>-->
            </el-dialog>

            <el-dialog title="在线问诊" append-to-body :visible.sync="dialogMaterial1" width="30%">
                <div style="display: flex;font-size: 1.2rem;padding-bottom: 1rem;cursor: pointer">
                    <div style="margin-left: 2rem;z-index: 999"
                        @click="detailInfo(item_order_state, itemss, item_text, item_order_id, item_doctor_remark, items)">
                        患者资料
                    </div>
                    <div style="padding-left:1rem;color: #3894ff;z-index: 999">在线问诊</div>
                </div>
                <doctor-im :order_states="order_states" :doctor_id="doctor_id" :order_id="order_id"
                    :doctor_pic="doctor_pic" :user_pic="user_pic" v-if="dialogMaterial1" ref="doctorim"
                    @Consultation="Consultation"></doctor-im>
            </el-dialog>


            <el-dialog title="详情" :visible.sync="dialogMaterial2" append-to-body width="30%">
                <div style="margin: 2%;padding: 2rem;">
                    <div style="padding: 1rem">开始时间: {{ patient_List.start_time }}</div>
                    <div style="padding: 1rem">医生备注: {{ patient_List.doctor_remark }}</div>
                    <div style="padding: 1rem">患者描述: {{ patient_List.case_description }}</div>
                    <div style="padding: 1rem">接诊方式: {{ patient_List.order_type === '1' ? '在线问诊' : '视频问诊' }}
                    </div>
                </div>
            </el-dialog>

            <!--//查看更多附件-->
            <el-dialog title="查看更多" :visible.sync="lookMore" width="30%">
                <div style="width: 90%;height: 500px;overflow-y: auto;padding: 20px;white-space: normal;margin: auto;">
                    <div style="width: 150px;height: 150px;float: left;padding: 10px" v-for="(item, index) in imageList"
                        :key="index">
                        <el-image style="width: 100%;height: 100%;" :src="item.file_url"
                            :preview-src-list="[item.file_url]">
                        </el-image>
                    </div>
                </div>
            </el-dialog>

            <!--        <TUICallKit :afterCalling="afterCalling" style="position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%); width: 50%;height: 50%;"/>-->

            <!--//视频问诊-->
            <!--        <el-dialog title="视频问诊" :visible.sync="videoMore" width="40%">-->
            <!--            <div style="width: 99%; height: 35rem; border: 1px solid salmon;">-->
            <!--                -->
            <!--            </div>-->
            <!--        </el-dialog>-->


            <el-dialog title="视频通话" :visible.sync="testrtcType" width="50%" :close-on-press-escape="false"
                :close-on-click-modal="false">
              <ConferenceMainView></ConferenceMainView>
            </el-dialog>


        </div>
    </div>

</template>
<script>
import {
    appointment,
    attachment,
    attachmentAdd,
    config, MeetingRecords,
    orderAll,
    orderEdit,
    orderShow,
    recordsAdd,
    recordsEdit,
    getUserQuest
} from "@/api";
import { ConferenceMainView,conference } from '@tencentcloud/roomkit-web-vue2.7';
import doctorIm from "@/components/doctorIm.vue";
import { Upload_Api_url } from '@/api/index.js';

export default {
    name: 'consultMater',
    components: { doctorIm,ConferenceMainView },
    data () {
        return {
            testrtcType: false,
            Upload_Api_urls: '',
            videoMore: false,
            user: 'user',
            doctor_id: '',
            doctor_pic: '',
            user_pic: '',
            textarea: '',
            options: [{
                value: '',
                label: '全部'
            }, {
                value: '1',
                label: '在线问诊'
            }, {
                value: '2',
                label: '视频问诊'
            }, {
                value: '3',
                label: '预约'
            }],
            options1: [{
                value: '',
                label: '全部'
            }, {
                value: '2',
                label: '进行中'
            }, {
                value: '3',
                label: '挂起'
            }, {
                value: '5',
                label: '已结束'
            }],
            //医生id
            user_id: '',
            //患者id
            patient_id: '',
            patient_name: '',
            patient_sex: '',
            patient_age: '',
            order_id: '',
            patient_case_description: '',
            imageList: [],
            //订单状态
            order_state: '',
            //订单类型
            order_type: '',
            orderList: [],
            orderList1: [],
            value: '',
            value1: '',
            dialogMaterial: false,
            dialogMaterial2: false,
            dialogMaterial1: false,
            activeIndex: '1',
            dialog_width: 1,
            token: '',
            fileLists: [],
            fileList: [],
            videoUrl: '',
            im: null,
            //往医生im中使用
            order_states: '',
            patient_List: [],
            item_id: '',
            items: [],
            item_order_state: '',
            itemss: [],
            item_text: '',
            item_order_id: '',
            item_doctor_remark: '',
            lightboxImages: [],
            lightboxVisible: false,
            lookMore: false,
            SDKAppID: '',
            SecretKey: '',
            call_type: 0,
            user_name: '',
            userIDList: [],
            en_user_name: '',
            en_doctor_name: '',
            user_role: '',
        }
    },
    computed: {
        // dialogWidth(){
        //     return this.dialog_width === 1 ? '50%' : '30%';
        // }
    },
    mounted () {
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id');
        this.en_user_name = localStorage.getItem('en_user_name');
        this.user_role = localStorage.getItem('user_role');
        this.dataList()
        // this.init()
        this.Upload_Api_urls = Upload_Api_url
    },
    methods: {
        switchOut (e) {
            if (e == 2) {
                this.$emit('loginOuts')
            }
        },
      async roomlogin(room_id){
        await conference.join(String(room_id), {
          isOpenCamera: false,
          isOpenMicrophone: false,
        });
      },
        //结束订单
        end_order (item) {
            orderEdit({
                order_id: item.id,
                user_id: item.user_id,
                order_state: 5,
            }).then(res => {
                if (res.data.code !== 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                } else {
                    this.dataList()
                }
            })
        },
        created () {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(now.getDate()).padStart(2, '0');
            const hour = String(now.getHours()).padStart(2, '0');
            const minute = String(now.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hour}:${minute}`;
        },
        //通话之前执行
        beforeCalling () {
            const json_str = { "time": this.created(), 'text': this.user_name + '发起' }
            const jsonStr = JSON.stringify(json_str);
            if (this.call_type === 1) {
                orderEdit({
                    order_id: this.order_id,
                    user_id: this.patient_id,
                    order_state: 2,
                    meetings_info: jsonStr,
                }).then(res => {
                    if (res.data.code === 1) {
                        this.call_type = 0
                    }
                })
            }
        },
        decrypt (encodedStr) {
            return atob(encodedStr);
        },
        look_more () {
            this.lookMore = true
        },
        //关闭问诊页面
        Consultation () {
            this.dialogMaterial1 = false
            this.dataList()
        },
        //查询预约订单
        appointmentList () {
            appointment({
                doctor_id: this.user_id,
                limit: 120,
                page: 1,
            }).then(res => {
                if (res.data.code === 1) {
                    this.orderList = res.data.data.data
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //现在接诊
        treatment (id, uid) {
            orderEdit({
                order_id: id,
                user_id: uid,
                order_state: 2,
            }).then(res => {
                if (res.data.code !== 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.dataList()
                    orderShow({
                        order_id: id,
                        user_id: uid,
                    }).then(res => {
                        if (res.data.code === 1) {
                            window.open(res.data.data.meetings_info.meeting_info_list[0].join_url)
                        } else {
                            this.$message({
                                message: '会议生成失败',
                                type: 'warning'
                            });
                        }

                    })
                }
            })
        },
        //在线问诊现在接诊
        chatTreatment (id, uid) {
            orderEdit({
                order_id: id,
                user_id: uid,
                order_state: 2,
            }).then(res => {
                if (res.data.code !== 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.dataList()
                    this.dialogMaterial1 = true
                }
            })
        },
        //隐藏身份证号码
        maskedIdNumber (idNumber) {
            if (idNumber.length === 18) {
                return idNumber.substring(0, 6) + '********' + idNumber.substring(14);
            } else {
                return '无效的身份证号';
            }
        },
        //切换患者资料
        switchPopup () {
            this.dialogMaterial = true
            this.dialogMaterial1 = false
        },
        handleSelect (key) {
            this.dialog_width = key
        },
        handleRemove (file, fileList) {
            console.log(file, fileList);
        },
        handlePreview (file) {
            console.log(file);
        },
        handleExceed (files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleSuccess (e) {
            attachmentAdd({
                user_id: this.patient_id,
                doctor_id: this.user_id,
                order_id: this.order_id,
                file_url: e.data['file_path'],
                file_name: e.data['name'],
            }).then(res => {
                if (res.data.code !== 1) {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                }
            })
            let array = {
                name: e.data['name'],
                url: e.data['url'],
            }
            this.fileList.push(array)
        },
        beforeUpload (file) {
            // 检查文件类型
            const isImage = file.type.startsWith('image/');
            // const isWord = file.name.endsWith('.doc') || file.name.endsWith('.docx') || file.name.endsWith('.xlsx') || file.name.endsWith('.pdf');
            if (!isImage) {
                // if (!isWord) {
                this.$message.error('只能上传图片文件');
                return false;
            }
            const maxSize = 2 * 1024 * 1024; // 2Mb
            if (file.size > maxSize) {
                this.$message.error('文件大小不能超过2Mb');
                return false;
            }
            return true;
        },
        //查询列表
        dataList () {
            orderAll({
                doctor_id: this.user_id,
                order_state: this.order_state,
                order_type: this.order_type,
                limit: 120,
                page: 1,
            }).then(res => {
                if (res.data.code === 1) {
                    this.orderList = res.data.data.data
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //下拉选项查询
        change (e) {
            if (this.value === '3') {
                this.appointmentList()
            } else {
                this.order_type = e
                this.order_state = ''
                this.dataList()
            }
        },
        change1 (e) {
            this.order_type = ''
            this.order_state = e
            this.dataList()
        },
        //查看详情
        async detailInfo (order_state, item, text, order_id, doctor_remark, items) {
            this.item_order_state = order_state
            this.itemss = item
            this.item_text = text
            this.item_order_id = order_id
            this.item_doctor_remark = doctor_remark
            this.item_id = order_id

            this.patient_id = item.id
            this.patient_name = item.name
            this.patient_sex = item.sex
            this.patient_age = item.age
            this.order_state = order_state
            this.order_id = order_id
            this.textarea = doctor_remark
            this.patient_case_description = text

            const { data } = await getUserQuest({ user_id: item.id })
            this.items = { ...items, info: JSON.parse((data.data.info || '')) }

            console.log(this.items, this.patient_id, this.patient_name, this.patient_sex, this.patient_age, this.order_id, this.textarea, this.patient_case_description)

            attachment({
                user_id: this.patient_id,
                order_id: order_id,
            }).then(res => {
                if (res.data.code === 1) {
                    this.imageList = res.data.data.data
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
            orderAll({
                user_id: this.patient_id,
                order_state: '',
                order_type: '',
                limit: 120,
                page: 1,
            }).then(res => {
                if (res.data.code === 1) {
                    this.orderList1 = res.data.data.data
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
            this.dialogMaterial = true
            this.dialogMaterial1 = false
        },
        //查看详情
        consultationDetails (item, array) {
            this.item_id = item
            this.items = array

            this.itemss = array.user
            this.item_order_state = array.order_state
            this.item_text = array.case_description
            this.item_order_id = array.id
            this.item_doctor_remark = array.doctor_remark

            this.order_states = array.order_state
            this.doctor_id = array.user_id
            this.doctor_pic = array.user.pic
            this.user_pic = array.doctor.pic
            this.order_id = array.id
            this.patient_id = item.id
            this.patient_name = item.name
            this.patient_sex = item.sex
            this.patient_age = item.age
            this.dialogMaterial1 = true
            this.dialogMaterial = false
        },
        //视频问诊详情
        videoDetails (item) {
          console.log(111)
            if (item.order_state === '5') {
                this.$message({
                    message: '订单已结束',
                    type: 'warning'
                });
                return
            } else {
                this.patient_id = item.user_id
                this.patient_name = item.user.name
                this.user_name = item.doctor.name
                this.order_id = item.id
                this.en_doctor_name = item.user.en_name
                orderShow({
                    order_id: item.id,
                    user_id: item.user_id,
                }).then(res => {
                    if (res.data.code === 1) {
                        const attendee = this.en_doctor_name + '_' + 'user_' + this.patient_id.toString()
                        const initiator = res.data.data.doctor.en_name + '_' + 'doctor_' + res.data.data.doctor_id.toString()
                        MeetingRecords({
                            meeting_source: 1,
                            organizer_id: res.data.data.doctor_id,
                            role: 2,
                            attendees_id: attendee + ',' + initiator,
                            order_id: item.id,
                        }).then(res1 => {
                            localStorage.setItem('roomid', res.data.data.id);
                            localStorage.setItem('userId', initiator);
                            localStorage.setItem('cache_ids', 'user' + res.data.data.user_id);
                            // this.testrtcType = true
                          this.$emit('testrtcType_change', {
                            video_room_type: 1,
                            video_room_id: res.data.data.meeting_records_id,
                            video_room_name: res.data.data.user.name,
                          });
                        })
                        // this.roomlogin(res.data.data.id)
                        // this.$emit('call_assembly', 2);
                    } else if (res.data.code == 401) {
                        this.$emit('loginOuts');
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
            // window.open(item.meetings_info.meeting_info_list[0].join_url)
        },
        // async participate_in(roomID,type,groupID){
        //     const hasPermissions = await this.checkMediaPermissions();
        //     if (hasPermissions === '没有授权摄像头或者没连接设备') {
        //         this.$message({
        //             message: '没有授权摄像头或者没连接设备,请到设置-权限中授权',
        //             type: 'warning'
        //         });
        //     }else if (hasPermissions === '没有授权麦克风或者没连接设备') {
        //         this.$message({
        //             message: '没有授权麦克风或者没连接设备,请到设置-权限中授权',
        //             type: 'warning'
        //         });
        //         return;
        //     }
        //     const params = {
        //         roomID: roomID,
        //         type: type,
        //         groupID: groupID
        //     };
        //     TUICallKitServer.joinInGroupCall(params).then(() => {
        //         // success
        //     }).catch(error => {
        //         console.error('joinInGroupCall error:', error);
        //     });
        // },
        //初始化视频
        // init() {
        //     config().then(res=>{
        //         if(res.data.code === 1){
        //             this.SDKAppID = this.decrypt(res.data.data.sdk_app_id)
        //             this.SecretKey = this.decrypt(res.data.data.sdk_secret_key)
        //             try {
        //                 const {userSig} = genTestUserSig({
        //                     userID: this.en_user_name+'_'+this.user_role+'_'+this.user_id.toString(),
        //                     SDKAppID: Number(this.SDKAppID),
        //                     SecretKey: this.SecretKey,
        //                 });
        //                 // 初始化
        //                 let promise = TUICallKitServer.init({
        //                     SDKAppID: Number(this.SDKAppID),
        //                     userID: this.en_user_name+'_'+this.user_role+'_'+this.user_id.toString(),
        //                     userSig,
        //                     // tim: this.tim     // 如果工程中已有 tim 实例，需在此处传入
        //                 }).then(success => {
        //                     console.log('success',success)
        //                 }).catch(error => {
        //                     console.log(error)
        //                 });
        //                 console.log('init_promise',promise)
        //             } catch (error) {
        //                 this.$message({
        //                     message: error,
        //                     type: 'warning'
        //                 });
        //             }
        //         }else{
        //             this.$message({
        //                 message: res.data.msg,
        //                 type: 'warning'
        //             });
        //         }
        //     })
        // },
        // async createGroupID() {
        //     const SDKAppID = this.SDKAppID
        //     const tim = TIM.create({ SDKAppID });
        //     const memberList = [];
        //     this.userIDList.forEach((userID) => {
        //         memberList.push({
        //             userID: userID
        //         });
        //     });
        //     const res = await tim.createGroup({
        //         type: TIM.TYPES.GRP_PUBLIC, // 必须是 public 群
        //         name: 'WebSDK',
        //         memberList
        //     });
        //     return res.data.group.groupID;
        // },
        //拨打
        // async call() {
        //   try {
        //     this.videoMore = true
        //     const hasPermissions = await this.checkMediaPermissions();
        //     if (hasPermissions === '没有授权摄像头或者没连接设备') {
        //         this.$message({
        //             message: '没有授权摄像头或者没连接设备,请到设置-权限中授权',
        //             type: 'warning'
        //         });
        //     }else if (hasPermissions === '没有授权麦克风或者没连接设备') {
        //         this.$message({
        //             message: '没有授权麦克风或者没连接设备,请到设置-权限中授权',
        //             type: 'warning'
        //         });
        //         return;
        //     }
        //     recordsAdd({
        //       attendees_id: this.en_doctor_name+'_'+'user_'+this.patient_id.toString(),
        //     }).then(res=>{
        //       if(res.data.code === 1){
        //         this.userIDList = res.data.data.userIDList
        //         const groupID = this.createGroupID();
        //         groupID.then(result => {
        //             recordsEdit({
        //                 id: res.data.data.id,
        //                 group_id: result,
        //                 meeting_source: 1,
        //                 organizer_id: this.user_id,
        //                 role: 2,
        //             }).then(res=>{
        //                 if(res.data.code !== 1){
        //                     this.$message({
        //                         message: '网络错误',
        //                         type: 'warning'
        //                     });
        //                 }
        //             })
        //             orderEdit({
        //                 meeting_records_id: res.data.data.id,
        //                 order_id: this.order_id,
        //                 user_id: this.patient_id,
        //                 order_state: 2,
        //             }).then(res => {
        //                 if (res.data.code !== 1) {
        //                     this.$message({
        //                         message: '网络错误',
        //                         type: 'success'
        //                     });
        //                 }
        //             })
        //           const callOptions = {
        //             userIDList: this.userIDList,
        //             groupID: result,
        //             type: TUICallType.VIDEO_CALL,
        //             roomID: res.data.data.roomID,
        //             timeout: res.data.data.timeout,
        //           };
        //           let promise = TUICallKitServer.groupCall(callOptions);
        //           promise.then(() => {
        //               console.warn('成功', res.data.data.id)
        //           }).catch(error => {
        //             console.warn('groupCall error:', error)
        //           });
        //         }).catch(error => {
        //           console.error(error);
        //         });
        //       }else{
        //         this.$message({
        //           message: '网络错误',
        //           type: 'warning'
        //         });
        //       }
        //     })
        //     // 这个段代码需要请求后端接口生成{userID: 'user'+this.patient_id.toString(), type: TUICallType.VIDEO_CALL,roomID:999}这部分内容。
        //     // await TUICallKitServer.call({userID: 'user'+this.patient_id.toString(), type: TUICallType.VIDEO_CALL,roomID:999});
        //     // console.log('发起通话')
        //   } catch (error) {
        //     this.videoMore = false
        //   }
        // },
        // async checkMediaPermissions() {
        //     try {
        //         const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        //         videoStream.getTracks().forEach(track => track.stop());
        //     } catch (videoError) {
        //         return '没有授权摄像头或者没连接设备'
        //     }
        //     try {
        //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //         stream.getTracks().forEach(track => track.stop());
        //         return true;
        //     } catch (error) {
        //         return '没有授权麦克风或者没连接设备'
        //     }
        // },
        //修改备注(医生)
        updateNotes () {
            orderEdit({
                user_id: this.patient_id,
                order_id: this.order_id,
                doctor_remark: this.textarea,
                order_state: this.order_state
            }).then(res => {
                if (res.data.code === 1) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dataList()
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //历史详情
        historicalDetails (id) {
            orderShow({
                order_id: id,
                user_id: this.patient_id,
            }).then(res => {
                if (res.data.code === 1) {
                    // this.patient_id = res.data.data.user.id
                    // this.patient_name = res.data.data.user.name
                    // this.patient_sex = res.data.data.user.sex
                    // this.patient_age = res.data.data.user.age
                    // this.order_id = res.data.data.id
                    // this.textarea = res.data.data.doctor_remark
                    // this.patient_case_description = res.data.data.case_description
                    this.patient_List = res.data.data
                    this.dialogMaterial2 = true
                } else if (res.data.code == 401) {
                    this.$emit('loginOuts');
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        wssetdata (e) {
            if (this.$refs.doctorim) {
                this.$refs.doctorim.wssetdata(e)
            }
        }
    }
}
</script>
<style>
#info .el-menu-item {
    height: 48px;
}

.content .el-dialog__body {
    padding: 0;
}
</style>
<style scoped>
.content {
    height: 100%;
    width: 100%;
    background-color: #f7f7f7cc;
    border: 1px #d6d8dc solid;
    z-index: 1;
}

.text-container {
    width: 90%;
    font-size: 15px;
    /*border: 1px #dddddd solid;*/
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 0 10px 5px #eeeeee80;
}

.text-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    /* 控制行数 */
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 15px;
    font-size: 0.8rem;
    line-height: 1.3rem;
    width: 40%;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100vh;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
    color: #eeeeee;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
}

.list_name {
    position: relative;
    width: 200px;
    height: 100%;
    background: #f0f9ff;
}

.list_name .el-menu-item {
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
    width: 100% !important;
    min-width: 100% !important;
}

.list_name .el-submenu__title {
    padding: 0 !important;
    height: 3rem;
}

.list_name .is-opened .el-submenu__title i {
    color: #fff !important;
}

.list_name .el-menu {
    width: 90%;
    border: none !important;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    width: 95vw;
    height: 70vh;
}
</style>
