<template>
    <div class="con">
<!--        <div class="image_style" :class="type == 1 ? 'active-type' : ''" @click="doctor">-->
<!--            <div class="image_title">我是医生</div>-->
<!--            <div class="image_img"><img src="@/assets/doctor.png" style="width: calc(12.9vw - 2px);height: calc(12.9vw - 2px)" alt="Vue Image"></div>-->
<!--        </div>-->
<!--        <div class="image_style" :class="type == 2 ? 'active-type' : ''" @click="patient">-->
<!--            <div class="image_title">我是患者</div>-->
<!--            <div class="image_img"><img src="@/assets/patient.png" style="width: calc(12.9vw - 2px);height: calc(12.9vw - 2px)" alt="Vue Image"></div>-->
<!--        </div>-->
        <div style="width: 96%;margin: auto;display: flex;justify-content: left;align-items: center;flex-wrap: wrap;overflow-y: auto;padding: 0 2%;">
            <div style="width: 20%;height: 320px;border-radius: 12px;border: 2px #0e92dd solid;margin: 1% 2%;" v-for="(item,index) in doctorList" :key="index" @click="doctor_info(item)">
                <div style="width: 100%;height: 65%;border-radius: 12px 12px 0 0;">
                    <img :src="item.pic" alt="Carousel Image" class="full-width-image" style="width: 100%;height: 100%;object-fit: cover;border-radius: 12px 12px 0 0;">
                </div>
                <div style="width: 100%;height: 35%;border-radius: 0 0 12px 12px;">
                    <div style="padding: 0.5rem 0;"> <span style="padding: 1rem;font-weight: bold;font-size: 15px;">{{ item.name }} </span><span style="color: #99a9bf;font-size: 15px;"> {{ item.title }}</span> </div>
<!--                    <div style="font-size: 8px;padding: 0 1rem;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ item.getDoctor.label }}</div>-->
                    <div style="padding: 0 1rem;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-size: 12px;">出诊医院: {{ item.hospital.name }}</div>
                    <div style="padding: 0 1rem;color: #0e92dd;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-size: 12px;">出诊时间: {{ item.date }}</div>
                    <div style="margin-top: 1rem;display: flex;align-items: center;justify-content: center;width: 100%;">
                        <span class="badge" @click="patient" v-if="item.is_appointment == '1'">
                            <div>
                                <img src="@/assets/one.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">在线问诊</div>
                        </span>
                        <span class="badge" @click="patient" v-if="item.is_ol_diagnose == '1'">
                            <div>
                                <img src="@/assets/two.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">视频问诊</div>
                        </span>
                        <span class="badge" @click="patient" v-if="item.is_video_diagnose == '1'">
                            <div>
                                <img src="@/assets/treen.png" alt="Carousel Image" class="full-width-image" style="width: 15px;height: 15px;object-fit: cover;border-radius: 12px 12px 0 0;">
                            </div>
                            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">预约</div>
                        </span>
                    </div>

                </div>
            </div>
        </div>

        <div class="con_below_page">
            <el-pagination
                    :page-size="8"
                    :pager-count="5"
                    background
                    layout="prev, pager, next"
                    @current-change="file_page"
                    :total="file_total">
            </el-pagination>
        </div>

    </div>
</template>
<script>
import {
    getAllList,
} from "@/api";
export default {
    name: 'notLogged',
    data() {
        return {
            type: 1,
            doctorList: [],
            file_total: 0,
            page: 1,
        }
    },
    mounted(){
        this.layoutList()
    },
    methods: {
        //获取当前时间
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以加 1
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        //列表分页
        file_page(e){
            this.page = e
            this.layoutList()
        },
        //医生排班列表
        layoutList(){
            getAllList({
                page: this.page,
                limit: 8,
                date: this.getCurrentDate(),
            }).then(res=>{
                if(res.data.code === 1){
                    this.doctorList = res.data.data.data
                    console.log(res.data.data.data)
                    this.file_total = Number(res.data.data.total)
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //医生详情
        doctor_info(item){
            this.$emit('idex_doctor_info',item.id)
        },
        // doctor(){
        //     this.type = 1;
        //     this.$emit('login_role','doctor')
        // },
        patient(){
            this.type = 2;
            this.$emit('login_role','patient')
        },
    }
}
</script>
<style scoped>

.con{
    z-index: 3;
    position: relative;
    margin-top: 0px;
    width: 84%;
    height: 17.6vw;
}

.image_style{
    width: 17.6vw;
    height: 17.6vw;
    border: 4px #fff solid;
    margin: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0px 5px 13px 0px rgba(0,0,0,0.08);
}

.active-type {
    border: 4px #017ED8 solid;
}

.image_title{
    padding-top: 0.7vw;
    font-size: 1vw;
    width: 100%;
    text-align: center;
}

.image_img{
    width: 12.9vw;
    height: 12.9vw;
    text-align: center;
    border: 1px dotted #aaa;
}
.con_below_page{
    width: 95%;
    height: 10%;
    margin: 0.5% auto 0;
    display: flex;
    justify-content: center;
}
.badge {
    width: 28%;
    margin-left: 2%;
    background-color: #0e92dd;
    border-radius: 3px;
    padding: 2px 5px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: center;
}

</style>
