<template>
    <div class="wrapper">
<!--      <div class="list_name" style="overflow-y: auto">-->
<!--        <el-menu-->
<!--            style="width: 100%;"-->
<!--            @select="switchOut"-->
<!--            background-color="#f0f4f7"-->
<!--            default-active="1"-->

<!--        >-->
<!--          <el-menu-item index="1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">医生端</el-menu-item>-->
<!--&lt;!&ndash;          <el-menu-item index="2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">患者端</el-menu-item>&ndash;&gt;-->
<!--        </el-menu>-->
<!--        <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">-->
<!--      </div>-->
      <div class="content">
        <div class="title">工作量统计</div>
        <div class="counting">
          <div class="counting_text">今日接诊: {{ workloadList.today_count }}</div>
          <div class="counting_separate"></div>
          <div class="counting_text">本月接诊: {{ workloadList.month_count }}</div>
          <div class="counting_separate"></div>
          <div class="counting_text">总接诊量: {{ workloadList.total_count }}</div>
          <div class="counting_separate"></div>
          <div class="counting_text">诊疗: {{ order_count.diagnosis_count }}</div>
          <div class="counting_separate"></div>
          <div class="counting_text">处方: {{ order_count.prescription_count }}</div>
        </div>
        <div class="time">
          <div class="block">
            <el-date-picker
                v-model="value1"
                type="datetime"
                size="mini"
                placeholder="选择日期时间"
                style="width: 100%;">
            </el-date-picker>
          </div>
          <div style="padding: 0 3%;">到</div>
          <div class="block">
            <el-date-picker
                v-model="value2"
                type="datetime"
                size="mini"
                placeholder="选择日期时间"
                style="width: 100%;">
            </el-date-picker>
          </div>
          <div class="block">
            <el-button style="font-weight: bold;margin: 10px 0;padding: 8px 15px" type="primary" size="mini" @click="timeQuery">查询</el-button>
          </div>
        </div>

        <div class="retrieval">
          <div style="padding: 2%;width: 48%;">
            <el-select v-model="value" placeholder="请选择" style="width: 100%;" size="mini" @change="change">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: 48%;font-size: 15px;padding: 5px;border-bottom: 2px #dddddd solid;">共计: {{ workloadList.total }}</div>
        </div>

        <div class="record">
          <div style="width: 100%;font-size: 15px;padding: 10px 0;display: flex;justify-content: space-between;align-items: center;" v-for="(item,index) in workloadList.data" :key="index">
            <div class="text">{{ item.start_time }}</div>
            <div class="text" style="width: 15%;text-align: center">
              {{item.order_type === "1" ? '在线问诊' : item.order_type === "2" ? '视频问诊' : item.order_type === "3" ? '预约' : ''}}
            </div>
            <div class="text">患者: {{ item.user.name }}</div>
            <div class="text" style="width: 25%;">{{ item.user.tel }}</div>
          </div>
        </div>

      </div>
    </div>
</template>
<script>
import {doctorInfo, orderAll, orderCount} from "@/api";

export default {
    name: 'workLoads',
    data() {
        return {
            value: '',
            value1: '',
            value2: '',
            options: [{
                value: '0',
                label: '全部'
            },{
                value: '1',
                label: '在线问诊'
            }, {
                value: '2',
                label: '视频问诊'
            }],
            user_id: '',
            workloadList: [],
            user_info: [],
            order_type: '',
            start_time: '',
            order_count: [],
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.workload()
        this.userInfo()
        this.orderCounts()
    },
    methods: {
      switchOut(e) {
        if (e == 2) {
          this.$emit('loginOuts')
        }
      },
        orderCounts(){
            orderCount({
                doctor_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.order_count = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }
            })
        },
        //医生详情
        userInfo(){
            doctorInfo({
                id: localStorage.getItem('user_id'),
            }).then(res=>{
                if(res.data.code === 1){
                    this.user_info = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }
            })
        },
        workload(){
            orderAll({
                doctor_id: this.user_id,
                order_type: this.order_type,
                start_time: this.start_time,
            }).then(res=>{
                if(res.data.code === 1){
                    this.workloadList = res.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        change(e){
            if(e === 0){
                this.order_type = ''
            }else{
                this.order_type = e
            }
            this.workload()
        },
        date(value){
            const date = new Date(value);
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            return date.toLocaleString('zh-CN', options);
        },
        //日期查询
        timeQuery(){
            this.start_time = [this.date(this.value1).replace(/\//g, '-'),this.date(this.value2).replace(/\//g, '-')];
            this.workload()
        }
    }
}
</script>
<style scoped>
    .content{
        height: 100%;
        width: 100%;
        background-color: #f7f7f7cc;
        border: 1px #d6d8dc solid;
        z-index: 1;
    }
    .title{
        font-size: 16px;
        padding: 2% 0 0 5%;
        font-weight: 600;
    }
    .counting{
        width: 85%;
        margin: 1rem auto;
        padding: 1.5% 0;
        border-radius: 10px;
        border: 2px #dddddd solid;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .counting_text{
        font-size: 15px;
        font-weight: 500;
    }
    .counting_separate{
        width: 2px;
        height: 50%;
        background-color: #dddddd;
    }
    .time{
        width: 85%;
        height: 8%;
        margin: 1rem auto;
        display: flex;
        align-items: center;
    }
    .block{
        width: 38%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .retrieval{
        width: 75%;
        height: 10%;
        margin: 1rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .record{
        width: 85%;
        margin: 0.5rem auto;
        overflow-y: auto;
        height: 40%;
    }
    .text{
        font-size: 15px;
        font-weight: 400;
    }
    .list_name {
      position: relative;
      width: 200px;
      height: 100%;
      background: #f0f9ff;
    }
    .list_name .el-menu-item{
      padding: 0 !important;
      height: 40px;
      line-height: 40px;
      width: 100% !important;
      min-width: 100% !important;
    }

    .list_name .el-submenu__title{
      padding: 0 !important;
      height: 3rem;
    }
    .list_name .is-opened .el-submenu__title i {
      color: #fff !important;
    }
    .list_name .el-menu{
      width: 90%;
      border: none !important;
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      width: 95vw;
      height: 70vh;
    }
</style>
