<template>
  <div class="wrapper">
    <div class="list_name" style="overflow-y: auto">
      <div style="display: flex;flex-flow: column nowrap;align-items: center;">
        <div class="left-item" v-for="(item, index) in menuLeftList" :key="index" @click="changeTarget(item, index)">
          <div class="left-img" :class="{'active': menuIndex == index}">
            <img :src="item.icon" alt="Menu">
          </div>
          <div class="left-item-title" >{{item.name}}</div>
        </div>
      </div>
      <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">
    </div>
    <div class="con">
      <div style="width: 100%;font-size: 19px;text-align: center;padding-top: 10px;position: relative">
        <div class="title_left" @click="backPage">
          <i class="el-icon-back"></i>
        </div>
        我的诊疗
      </div>
      <div style="width: 90%;height: 86%;border-radius: 10px;margin: 1rem auto 3rem; overflow-y: auto;border: 1px #ddd solid;">
        <div style="width: 90%;margin: 0.5rem auto;font-size: 1.3rem;padding: 1rem;border-bottom: 2px #ccc solid;" v-for="(item,index) in diagnosisLists" :key="index">
          <div style="display: flex;justify-content: space-between;">
            <div>编号: {{item.order_num}}</div>
            <div>时间: {{item.create_time}}</div>
          </div>
          <div class="text" style="text-align: left;font-size: 16px;">医生: {{ item.doctor.name }} 诊断: <span v-for="(item2,index2) in item.userDiagnose" :key="index2"> {{ item2.describe }}  </span> </div>
          <div class="text" style="text-align: left;font-size: 16px;">{{ item.order_type === '1' ? '诊疗' : '处方' }}: <span v-for="(item1,index1) in item.userPrescription" :key="index1"> {{ item1.prescription.name }}x{{ item1.number }}{{ item1.unit }} </span></div>
          <div>备注: {{item.remark}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {diagnosisOrder} from "@/api";

export default {
    name: 'diagnosisInfo',
    data() {
        return {
          menuIndex: 1,
          menuLeftList: [
            {
              icon: "/img/pa4.png",
              name: "中医",
              activeIcon: "/img/pa7.png",
              target: "chinese_doctor"
            },
            {
              icon: "/img/pa2.png",
              name: "医技",
              activeIcon: "/img/pa5.png",
              target: "doctor_technology"
            },
            {
              icon: "/img/pa3.png",
              name: "妇幼",
              activeIcon: "/img/pa6.png",
            }
          ],
            diagnosisLists: [],
            user_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id')
        this.init()
    },
    methods: {
      changeTarget(item, index) {
        this.menuIndex = index;
        // this.$emit("chioce", item.target)
        this.$emit('backPage', item.target);
      },
      switchOut(e) {
        if (e == 2) {
          this.$emit('loginOuts')
        }
      },
      backPage() {
        this.$emit('backPage')
      },
        init(){
            diagnosisOrder({
                page: 1,
                limit: 999,
                order_type: 0,
                user_id: this.user_id,
            }).then(res1=>{
                if(res1.data.code === 1){
                    this.diagnosisLists = res1.data.data.data
                }else{
                    this.$message({
                        message: res1.data.msg,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>
<style scoped>
.con {
  height: 100%;
  width: calc(100% - 210px);
    background-color: #f7f7f7cc;
    border: 1px #d6d8dc solid;
    z-index: 1;
  .title_left {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-20%);
    font-size: 30px;
    font-weight: bolder;
    &:hover {
      color: #017ed8;
    }
  }
}
.list_name {
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  width: 200px;
  height: 100%;
  background: #f0f9ff;
}
.list_name .el-menu-item{
  padding: 0 !important;
  height: 40px;
  line-height: 40px;
  width: 100% !important;
  min-width: 100% !important;
}

.list_name .el-submenu__title{
  padding: 0 !important;
  height: 3rem;
}
.list_name .is-opened .el-submenu__title i {
  color: #fff !important;
}
.list_name .el-menu{
  width: 90%;
  border: none !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  height: 70vh;
}
.left-item {
  color: #017ed8;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  .left-img {
    width: 87px;
    height: 87px;
    box-sizing: border-box;
    background: #D5E7F3;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
      height: 48px;

    }
  }
}
.left-img.active {
  background: #FFFFFF;
  border: 2px solid #017ED8;
}
</style>
