<template>
    <div class="breadcrumb-wrap">
       <span @click="home" style="cursor: pointer">首页</span>   <span>我的位置：</span>
        <span v-for="(item, index) in tabs"><template v-if="index!==0">/</template>{{item}}</span>
    </div>
</template>
<script>
export default {
    name: 'Breadcrumb',
    props: {
        tabs: {
            type: Array,
            default: []
        },
    },
    data() {
        return {

        }
    },
    methods: {
        home() {
          this.$emit('home');
        },
    },
    mounted() {

    }
}
</script>
<style scoped>

.con{
    width: 84%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_style{
    width: 250px;
    height: 250px;
    border: 2px #017ED8 solid;
    margin: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.image_title{
    padding-top: 20px;
    font-size: 25px;
    width: 100%;
    text-align: center;
}

.image_img{
    width: 100%;
    text-align: center;
}

</style>
