<template>
    <div class="con">
        <el-row class="tac" style="height: 100%;overflow-y: hidden;" ref="tac">
            <el-col :span="7">
                <div class="banner-box">
                    当前位置：<span @click="click_text('问诊资料')">首页</span><span class="banner-icon">></span> <span @click="click_text(text)">{{ text }}</span>
                </div>
            </el-col>
            <el-col :span="17">
                <el-menu
                    :default-active="defaultType"
                    class="el-menu-vertical-demo custom-menu"
                    @select="selectMenu"
                    background-color="#017ED8"
                    text-color="#fff"
                    mode="horizontal"
                    active-text-color="#ffffff">
                    <el-menu-item index="1" class="menu_style">
                        <span slot="title" style="font-size: 16px;">问诊资料</span>
                    </el-menu-item>
<!--                    <el-menu-item index="2" class="menu_style">-->
<!--                        <span slot="title" style="font-size: 16px;">上传资料</span>-->
<!--                    </el-menu-item>-->
                    <el-menu-item index="3" class="menu_style">
                        <span slot="title" style="font-size: 16px;">患者登记</span>
                    </el-menu-item>
                    <el-menu-item index="4" class="menu_style">
                        <span slot="title" style="font-size: 16px;">会议培训</span>
                    </el-menu-item>
                    <el-menu-item index="5" class="menu_style">
                        <span slot="title" style="font-size: 16px;">患者诊疗</span>
                    </el-menu-item>
                    <el-menu-item index="8" class="menu_style">
                        <span slot="title" style="font-size: 16px;">群组聊天</span>
                    </el-menu-item>
                    <el-menu-item index="99" class="menu_style">
                        <span slot="title" style="font-size: 16px;">我的云盘</span>
                    </el-menu-item>
                    <el-menu-item index="7" class="menu_style">
                        <span slot="title" style="font-size: 16px;">医学论坛</span>
                    </el-menu-item>
                    <el-menu-item index="9" class="menu_style">
                        <span slot="title" style="font-size: 16px;">患者随访</span>
                    </el-menu-item>
<!--                    <el-menu-item class="menu_style">-->
<!--                        <span slot="title" style="font-size: 16px;">医疗知识库</span>-->
<!--                    </el-menu-item>-->
                    <el-menu-item index="6" class="menu_style">
                        <span slot="title" style="font-size: 16px;">工作统计</span>
                    </el-menu-item>
                </el-menu>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: 'doctorMenu',
    props: {
        defaultType: {
            type: String,
            default: "1"
        },
        pageType: {
            type: String,
            default: "default"
        }
    },
    data() {
        return {
            tacHeight: 0,
            text: '问诊资料',//菜单名称
        }
    },
    mounted() {
        console.log(String(this.defaultType),'默认')
      this.$nextTick(() => {
        this.tacHeight = this.$refs.tac.$el.offsetHeight - 1
      })
    },
    methods: {
        onSubmit(){

        },
        selectMenu(e) {
            if (e == '1'){
                this.text = '问诊资料'
            }else if(e == '3'){
                this.text = '患者登记'
            }else if(e == '4'){
                this.text = '会议培训'
            }else if(e == '5'){
                this.text = '患者诊疗'
            }else if(e == '8'){
                this.text = '群组聊天'
            }else if(e == '99'){
                this.text = '我的云盘'
            }else if(e == '7'){
                this.text = '医学论坛'
            }else if(e == '9'){
                this.text = '患者随访'
            }else if(e == '6'){
                this.text = '工作统计'
            }
            e = String(e)
            if (this.pageType == "forum") {
                return window.open('/?menu=' + e, '_self');
            }
            this.$emit('menuIndex',e);
        },
        click_text(text){
            if (text == '问诊资料'){
                this.$emit('menuIndex',1);
            }else if(text == '患者登记'){
                this.$emit('menuIndex',3);
            }else if(text == '会议培训'){
                this.$emit('menuIndex',4);
            }else if(text == '患者诊疗'){
                this.$emit('menuIndex',5);
            }else if(text == '群组聊天'){
                this.$emit('menuIndex',8);
            }else if(text == '我的云盘'){
                this.$emit('menuIndex',99);
            }else if(text == '医学论坛'){
                this.$emit('menuIndex',7);
            }else if(text == '患者随访'){
                this.$emit('menuIndex',9);
            }else if(text == '工作统计'){
                this.$emit('menuIndex',6);
            }
        }
    }
}
</script>
<style>

</style>
<style scoped>
.con{
    width: 100%;
    height: 56px;
    border: 1px #d6d8dc solid;
    background-color:#017ED8;
    position: relative;
    z-index: 0;
}
.menu_style{
    /* border-bottom: 1px #d6d8dc solid; */
    height: 56px;
    line-height: 56px;
    cursor: pointer;
}
.tac ::v-deep .el-menu-item.is-active {
    background-color: #15669F !important;
    border-bottom-color: #15669F !important;
}
.banner-box {
    height: 56px;
    line-height: 56px;
    padding-right: 108px;
    text-align: right;
    background-color: #017ED8;
    color: #fff;
    font-size: 16px;
}
.banner-icon {
    margin: 0 5px;
}
</style>
