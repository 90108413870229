import { render, staticRenderFns } from "./userMenu.vue?vue&type=template&id=fc28e00a&scoped=true"
import script from "./userMenu.vue?vue&type=script&lang=js"
export * from "./userMenu.vue?vue&type=script&lang=js"
import style1 from "./userMenu.vue?vue&type=style&index=1&id=fc28e00a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc28e00a",
  null
  
)

export default component.exports