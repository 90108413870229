<template>
  <div class="wrapper">
    <div class="list_name" style="overflow-y: auto">
      <div style="display: flex;flex-flow: column nowrap;align-items: center;">
        <div class="left-item" v-for="(item, index) in menuLeftList" :key="index" @click="changeTarget(item, index)">
          <div class="left-img" :class="{'active': menuIndex == index}">
            <img :src="item.icon" alt="Menu">
          </div>
          <div class="left-item-title" >{{item.name}}</div>
        </div>
      </div>
      <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">
    </div>
    <div class="con">
      <div style="height: 13%;border-bottom: 1px #dddddd solid;position: relative">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">全部</el-menu-item>
          <el-menu-item index="2">预约单</el-menu-item>
          <el-menu-item :index="'state+'+index" v-for="(item,index) in orderState_list" :key="index"
                        v-if="index !== 0">{{ item.name }}
          </el-menu-item>
        </el-menu>
        <div class="title_left" @click="backPage">
          返回
        </div>
      </div>
      <div style="width:100%;height: 80%;overflow-y: auto;">
        <div style="width: 85%;height: 23%;margin: 0 auto;display: flex;align-items: center;cursor: pointer;justify-content: left;padding: 0 10px;"
            v-for="(item,index) in orderList" :key="index">
          <div style="width: 12%;height:100%;display: flex;justify-content: left;align-items: center;">
            <el-avatar :size="65" :src="item.doctor.pic"></el-avatar>
          </div>
          <div style="width: 88%;">
            <div>
              <span style="font-size: 16px;margin-left: 10px;">{{ item.doctor.name }}</span>
              <span style="margin-left: 10px;">{{ item.doctor.title }}</span>
              <span style="margin-left: 10px;background-color: #0e92dd;border-radius: 10px;padding: 2px;color: #ffffff;" v-if="item.order_type === '2'" @click="Jump(item)">视频问诊</span>
              <span style="margin-left: 10px;background-color: #0e92dd;border-radius: 10px;padding: 2px;color: #ffffff;" @click="ask(item.doctor.id,item)" v-else-if="item.order_type === '1'">在线问诊</span>
              <span style="margin-left: 10px;background-color: #0e92dd;border-radius: 10px;padding: 2px;color: #ffffff;" v-else>预约</span>
              <span style="margin-left: 10px;background-color: #0e92dd;border-radius: 10px;padding: 2px 5px;color: #ffffff;">
                {{
                  item.order_state === "1" ? '未开始' : item.order_state === "2" ? '进行中' : item.order_state === "3" ? '挂起' : item.order_state === "4" ? '用户自己取消' : item.order_state === "5" ? '已结束' : ''
                }}
            </span>
              <span style="margin-left: 10px;background-color: #0e92dd;border-radius: 10px;padding: 2px 5px;color: #ffffff;cursor: pointer" v-if="item.order_state === '2' && item.order_type === '2'" @click="end_order(item)">结束订单</span>
            </div>
            <div class="ellipsis" v-html="item.doctor.intro"></div>
          </div>
        </div>
      </div>
      <el-dialog title="问诊详情" append-to-body :visible.sync="dialogMaterial" width="30%">
        <user-im :order_states="order_states" :doctor_id="doctor_id" :order_id="order_id" :doctor_pic="doctor_pic"
                 :user_pic="user_pic" v-if="dialogMaterial" ref="doctorim" @Consultation="Consultation"></user-im>
      </el-dialog>

<!--      <el-dialog title="视频通话" append-to-body :visible.sync="testrtcType" width="50%" :close-on-press-escape="false" :close-on-click-modal="false">-->
<!--        <ConferenceMainView></ConferenceMainView>-->
<!--      </el-dialog>-->

      <!--    <TUICallKit style="position: fixed;top: 50%;left: 50%; transform: translate(-50%, -50%); width: 50%;height: 50%;"-->
      <!--                :afterCalling="afterCalling"-->
      <!--    />-->
      <!--        &lt;!&ndash;//视频问诊&ndash;&gt;-->
      <!--        <el-dialog title="视频问诊" :visible.sync="videoMore" width="40%">-->
      <!--            <div style="width: 99%; height: 35rem; border: 1px solid salmon;">-->
      <!--&lt;!&ndash;                <TUICallKit :beforeCalling="beforeCalling"/>&ndash;&gt;-->
      <!--            </div>-->
      <!--        </el-dialog>-->
    </div>
  </div>
</template>
<script>
import {
    appointment,
    config,
    MeetingRecords,
    orderAll,
    orderEdit,
    orderShow,
    orderState,
    recordsAdd,
    recordsEdit
} from "@/api";
import userIm from '@/components/userIm.vue'
import genTestUserSig from "@/debug/GenerateTestUserSig-es.js";
import { ConferenceMainView,conference } from '@tencentcloud/roomkit-web-vue2.7';


export default {

  name: 'myConsultation',
  components: {
      userIm
  },
  data() {
    return {
      menuIndex: 1,
      menuLeftList: [
        {
          icon: "/img/pa4.png",
          name: "中医",
          activeIcon: "/img/pa7.png",
          target: "chinese_doctor"
        },
        {
          icon: "/img/pa2.png",
          name: "医技",
          activeIcon: "/img/pa5.png",
          target: "doctor_technology"
        },
        {
          icon: "/img/pa3.png",
          name: "妇幼",
          activeIcon: "/img/pa6.png",
        }
      ],
      testrtcType: false,
      videoMore: false,
      user: 'doctor',
      activeIndex: '1',
      activeIndex2: '1',
      dialogMaterial: false,
      user_id: '',
      user_role: '',
      orderList: [],
      order_state: '',
      orderState_list: [],
      client_id: '',
      im_id: '',
      doctor_id: '',
      doctor_pic: '',
      user_pic: '',
      order_id: '',
      typeNum: 1,
      //往医生im中使用
      order_states: '',
      keyText: '',
      SDKAppID: '',
      SecretKey: '',
      patient_id: '',
      call_type: 0,
      en_user_name: '',
      en_doctor_name: '',
    }
  },
  mounted() {
    this.user_id = localStorage.getItem('user_id');
    this.user_role = localStorage.getItem('user_role');
    this.client_id = localStorage.getItem('client_id');
      this.en_user_name = localStorage.getItem('en_user_name');
    this.allOrders()
    this.orderStateList()
    this.init()
  },
  methods: {
    changeTarget(item, index) {
      this.menuIndex = index;
      // this.$emit("chioce", item.target)
      this.$emit('backPage', item.target);
    },
    switchOut(e) {
      if (e == 1) {
        this.$emit('loginOuts')
      }
    },
    backPage() {
      this.$emit('backPage')
    },
      //结束订单
      end_order(item){
          orderEdit({
              order_id: item.id,
              user_id: item.user_id,
              order_state: 5,
          }).then(res => {
              if (res.data.code !== 1) {
                  this.$message({
                      message: res.data.msg,
                      type: 'warning'
                  });
              }else{
                  this.allOrders()
              }
          })
      },
    afterCalling() {
      console.log('医生退出了')
    },
    created() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      const day = String(now.getDate()).padStart(2, '0');
      const hour = String(now.getHours()).padStart(2, '0');
      const minute = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day} ${hour}:${minute}`;
    },
    //通话之前执行
    beforeCalling() {
      const json_str = {"time": this.created(), 'text': this.user_name + '发起'}
      const jsonStr = JSON.stringify(json_str);
      if (this.call_type === 1) {
        orderEdit({
          order_id: this.order_id,
          user_id: this.user_id,
          order_state: 2,
          meetings_info: jsonStr,
        }).then(res => {
          if (res.data.code === 1) {
            this.call_type = 0
          }
        })
      }
    },
    decrypt(encodedStr) {
      return atob(encodedStr);
    },
    //关闭问诊页面
    Consultation() {
      this.dialogMaterial = false
      // this.allOrders()
      if (this.keyText === "1") {
        this.allOrders()
      } else if (this.keyText === "2") {
        this.appointmentList()
      } else {
        const parts = this.keyText.split('+');
        const number = parseInt(parts[1]);
        console.log(number, '选择');
        if (number === 0) {
          this.order_state = 2
          this.allOrders()
        } else if (number === 1) {
          this.order_state = 3
          this.allOrders()
        } else {
          this.order_state = 5
          this.allOrders()
        }
      }
    },
    //获取订单状态列表
    orderStateList() {
      orderState().then(res => {
        if (res.data.code === 1) {
          this.orderState_list = res.data.data
          console.log(this.orderState_list)
        } else if (res.data.code == 401) {
          this.$emit('loginOuts');
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    //在线问诊
    ask(id, array) {
      this.order_states = array.order_state
      this.typeNum++
      this.doctor_pic = array.doctor.pic
      this.user_pic = array.user.pic
      this.order_id = array.id
      this.doctor_id = id
      this.dialogMaterial = true
    },
    //跳转会议
    async Jump(item) {
      if (item.order_state === '5') {
        this.$message({
          message: '订单已结束',
          type: 'warning'
        });
        return
      } else {
        this.patient_id = item.doctor_id
        this.user_name = item.user.name
        this.patient_name = item.doctor.name
        this.order_id = item.id
        this.en_doctor_name = item.doctor.en_name
        orderShow({
          order_id: item.id,
          user_id: item.user_id,
        }).then(res => {
          if (res.data.code === 1) {
              const attendee = item.user.en_name+'_'+'user_'+item.user.id.toString()
              const initiator = res.data.data.doctor.en_name+'_'+'doctor_'+res.data.data.doctor_id.toString()
              MeetingRecords({
                  meeting_source: 1,
                  organizer_id: this.user_id,
                  role: 1,
                  attendees_id: attendee+','+initiator,
                  order_id: item.id,
              }).then(res1 => {
                  localStorage.setItem('roomid', res.data.data.id);
                  localStorage.setItem('userId', attendee);
                  localStorage.setItem('is_type', 1);
                  localStorage.setItem('cache_ids', 'doctor'+res.data.data.doctor_id);
                  // this.testrtcType = true;
                  console.log(res.data.data,888)
              })
            // this.roomlogin(res.data.data.id)
            this.$emit('call_assembly',2);
            this.$emit('testrtcType_change', {
                video_room_type: 1,
                video_room_id: res.data.data.meeting_records_id,
                video_room_name: res.data.data.user.name,
            });
          } else if (res.data.code == 401) {
            this.$emit('loginOuts');
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            });
          }
        })
      }
    },
      async participate_in(roomID, type, groupID) {
          const hasPermissions = await this.checkMediaPermissions();
          if (hasPermissions === '没有授权摄像头或者没连接设备') {
              this.$message({
                  message: '没有授权摄像头或者没连接设备,请到设置-权限中授权',
                  type: 'warning'
              });
          }else if (hasPermissions === '没有授权麦克风或者没连接设备') {
              this.$message({
                  message: '没有授权麦克风或者没连接设备,请到设置-权限中授权',
                  type: 'warning'
              });
              return;
          }
      const params = {
        roomID: roomID,
        type: type,
        groupID: groupID
      };
    },
    async roomlogin(room_id){
      await conference.join(String(room_id), {
        isOpenCamera: false,
        isOpenMicrophone: false,
      });
    },
    //初始化视频
    init() {
      config().then(res => {
        if (res.data.code === 1) {
          this.SDKAppID = this.decrypt(res.data.data.sdk_app_id)
          this.SecretKey = this.decrypt(res.data.data.sdk_secret_key)
          try {
            const {userSig} = genTestUserSig({
              userID: this.en_user_name+'_'+this.user_role+'_'+this.user_id.toString(),
              SDKAppID: Number(this.SDKAppID),
              SecretKey: this.SecretKey,
            });
          } catch (error) {
            this.$message({
              message: error,
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    async createGroupID() {
      const SDKAppID = this.SDKAppID

      const memberList = [];
      this.userIDList.forEach((userID) => {
        memberList.push({
          userID: userID
        });
      });
      const res = await tim.createGroup({
        type: TIM.TYPES.GRP_PUBLIC, // 必须是 public 群
        name: 'WebSDK',
        memberList
      });
      return res.data.group.groupID;
    },
    //拨打
    async call() {
      try {
        this.videoMore = true
          const hasPermissions = await this.checkMediaPermissions();
          if (hasPermissions === '没有授权摄像头或者没连接设备') {
              this.$message({
                  message: '没有授权摄像头或者没连接设备,请到设置-权限中授权',
                  type: 'warning'
              });
          }else if (hasPermissions === '没有授权麦克风或者没连接设备') {
              this.$message({
                  message: '没有授权麦克风或者没连接设备,请到设置-权限中授权',
                  type: 'warning'
              });
              return;
          }
        recordsAdd({
          attendees_id: this.en_doctor_name+'_'+'doctor_'+this.patient_id.toString(),
        }).then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.userIDList = res.data.data.userIDList
            const groupID = this.createGroupID();
            console.log('groupID', groupID)
            groupID.then(result => {
                recordsEdit({
                    id: res.data.data.id,
                    group_id: result,
                    meeting_source: 1,
                    organizer_id: this.user_id,
                    role: 1,
                }).then(res => {
                    if (res.data.code !== 1) {
                        this.$message({
                            message: '网络错误',
                            type: 'warning'
                        });
                    }
                })
                orderEdit({
                    meeting_records_id: res.data.data.id,
                    order_id: this.order_id,
                    user_id: this.user_id,
                    order_state: 2,
                }).then(res => {
                    if (res.data.code !== 1) {
                        this.$message({
                            message: '网络错误',
                            type: 'success'
                        });
                    }
                })
            }).catch(error => {
              console.error(error);
            });
          } else {
            this.$message({
              message: '网络错误',
              type: 'warning'
            });
          }
        })
        // 这个段代码需要请求后端接口生成{userID: 'user'+this.patient_id.toString(), type: TUICallType.VIDEO_CALL,roomID:999}这部分内容。
        // await TUICallKitServer.call({userID: 'user'+this.patient_id.toString(), type: TUICallType.VIDEO_CALL,roomID:999});
        // console.log('发起通话')
      } catch (error) {
        this.videoMore = false
      }
    },
      async checkMediaPermissions() {
          try {
              const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
              videoStream.getTracks().forEach(track => track.stop());
          } catch (videoError) {
              return '没有授权摄像头或者没连接设备'
          }
          try {
              const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
              stream.getTracks().forEach(track => track.stop());
              return true;
          } catch (error) {
              return '没有授权麦克风或者没连接设备'
          }
      },
    handleSelect(key) {
      this.keyText = key
      if (key === "1") {
        this.allOrders()
      } else if (key === "2") {
        this.appointmentList()
      } else {
        const parts = key.split('+');
        const number = parseInt(parts[1]);
        console.log(number, '选择');
        if (number === 1) {
          this.order_state = 2
          this.allOrders()
        } else if (number === 2) {
          this.order_state = 3
          this.allOrders()
        } else {
          this.order_state = 5
          this.allOrders()
        }
      }
      this.order_state = ''
    },
    //查询订单
    allOrders() {
      orderAll({
        user_id: this.user_id,
        order_state: this.order_state,
        limit: 120,
        page: 1,
      }).then(res => {
        if (res.data.code === 1) {
          this.orderList = res.data.data.data
        } else if (res.data.code == 401) {
          this.$emit('loginOuts');
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    //查询预约订单
    appointmentList() {
      appointment({
        user_id: this.user_id,
        limit: 120,
        page: 1,
      }).then(res => {
        if (res.data.code === 1) {
          this.orderList = res.data.data.data
        } else if (res.data.code == 401) {
          this.$emit('loginOuts');
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      })
    },
    wssetdata(e) {
      if (this.$refs.doctorim) {
        this.$refs.doctorim.wssetdata(e)
      }
    }
  }
}
</script>
<style scoped>
.con {
  height: 100%;
  width: calc(100% - 210px);
    background-color: #f7f7f7cc;
    border: 1px #d6d8dc solid;
    z-index: 1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-left: 10px;
}
.title_left {
  position: absolute;
  right: 20px;
  top: 25%;
  transform: translateY(-50%);
  cursor: pointer;
  &:hover {
    color: #017ed8;
  }
}
.list_name {
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  width: 200px;
  height: 100%;
  background: #f0f9ff;
}
.list_name .el-menu-item{
  padding: 0 !important;
  height: 40px;
  line-height: 40px;
  width: 100% !important;
  min-width: 100% !important;
}

.list_name .el-submenu__title{
  padding: 0 !important;
  height: 3rem;
}
.list_name .is-opened .el-submenu__title i {
  color: #fff !important;
}
.list_name .el-menu{
  width: 90%;
  border: none !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  height: 70vh;
}
.left-item {
  color: #017ed8;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  .left-img {
    width: 87px;
    height: 87px;
    box-sizing: border-box;
    background: #D5E7F3;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
      height: 48px;

    }
  }
}
.left-img.active {
  background: #FFFFFF;
  border: 2px solid #017ED8;
}
</style>
