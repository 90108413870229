import axios from 'axios';
// const Api_url='https://myg.zeyan.wang/index.php/api/myg/'
const Api_url = 'https://myg.ysjt1791.com/index.php/api/myg/'
// const Api_url='http://172.16.96.127/index.php/api/myg/'
export const Upload_Api_url = 'https://myg.ysjt1791.com/index.php/api/myg/common/uploadFile';
//  export const Back_Web_Url = "https://myg.zeyan.wang" // 后台登录跳转地址
export const Back_Web_Url = "https://myg.ysjt1791.com" // 后台登录跳转地址
// export const Upload_Api_url = 'https://myg.zeyan.wang/index.php/api/myg/common/uploadFile';

// 创建 axios 实例
const instance = axios.create({
    baseURL: Api_url, // 设置基本 URL
});
const instance1 = axios.create({
    baseURL: Api_url, // 设置基本 URL
});
// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // 从本地存储中获取 token，你可以根据实际情况修改获取 token 的方式
        if (token) {
            config.headers.token = `${token}`; // 在请求的 header 中添加 token
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 请求拦截器
instance1.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // 从本地存储中获取 token，你可以根据实际情况修改获取 token 的方式
        config.crossDomain = true
        if (token) {
            config.headers.token = `${token}`; // 在请求的 header 中添加 token
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//获取验证码
export function sendSmS(data) {
    return axios.post(Api_url + 'sendSmS', data);
}

//手机号验证码登录
export function telLogin(data) {
    return axios.post(Api_url + 'telLogin', data);
}

//手机号密码登录
export function pwdLogin(data) {
    return axios.post(Api_url + 'pwdLogin', data);
}

//退出登录
export function loginOut(data) {
    return instance.post('loginOut', data);
}

//获取医生详情
export function doctorInfo(data) {
    return instance.get('doctor', {params: data});
}

//获取医生详情
export function register(data) {
    return instance.post('register', data);
}

//获取疾病简介列表
export function disease() {
    return instance.get('disease');
}

//添加患者档案
export function filesAdd(data) {
    return instance.post('medicalRecords/add', data);
}

//获取患者详情
export function user(data) {
    return instance.get('user', {params: data});
}

//修改账号信息
export function userEdit(data) {
    return instance.post('user/edit', data);
}

//修改手机号码
export function editTel(data) {
    return instance.post('editTel', data);
}

//患者档案列表
export function medicalRecords(data) {
    return instance.get('medicalRecords', {params: data});
}

//更新患者档案
export function medicalRecordsEdit(data) {
    return instance.post('medicalRecords/edit', data);
}

//更新患者档案
export function medicalRecordsDelAll(data) {
    return instance.post('medicalRecords/delAll', data);
}

//获取患者附件列表
export function attachment(data) {
    return instance.get('attachment', {params: data});
}

//获取科室分类树形菜单
export function getMenuList(data) {
    return instance.get('medicalDepartment/getMenuList', {params: data});
}

//获取医院的地区列表
export function addressList() {
    return instance.get('hospital/addressList');
}

//医生排班列表
export function getList(data) {
    return instance.get('schedules/getList', {params: data});
}

//医生排班列表-首页
export function getAllList(data) {
    return instance.get('schedules/getAllList', {params: data});
}

//创建预约订单
export function orderAdd(data) {
    return instance.post('order/add', data);
}

//获取订单列表
export function orderAll(data) {
    return instance.get('order', {params: data});
}

//获取订单列表
export function resetPassword(data) {
    return instance.post('resetPassword', data);
}

//修改订单信息
export function orderEdit(data) {
    return instance.post('order/edit', data);
}

//上传患者附件(单文件上传)(医生/患者用)
export function attachmentAdd(data) {
    return instance.post('attachment/add', data);
}

//订单详情
export function orderShow(data) {
    return instance.get('order/show', {params: data});
}

//医生端添加用户
export function userAdd(data) {
    return instance.post('user/add', data);
}

//获取诊疗列表
export function diagnose() {
    return instance.get('diagnose');
}

//获取处方列表
export function prescription(data) {
    return instance.get('prescription', {params: data});
}

//患者诊疗订单-添加
export function diaOrderAdd(data) {
    return instance.post('diagnosisOrder/add', data);
}


//获取患者诊疗列表
export function userDiagnose(data) {
    return instance.get('userDiagnose', {params: data});
}

//获取患者处方记录列表
export function userPrescription(data) {
    return instance.get('userPrescription', {params: data});
}

//获取会议的房间号
export function MeetingRecords(data) {
    return instance.post('meeting_records/add', data);
}

//加入房间
export function joinRoom(data) {
    return instance.post('meeting_records/joinRoom', data);
}

//退出房间
export function exitRoom(data) {
    return instance.post('meeting_records/exitRoom', data);
}

//
export function addUserMeetingRecordsList(data) {
    return instance.post('meeting_records/addUserMeetingRecordsList', data);
}

export function getUserMeetingRecordsList(data) {
    return instance.get('meeting_records/getUserMeetingRecordsList', {params: data});
}

export function delUserMeetingRecordsList(data) {
    return instance.post('meeting_records/delUserMeetingRecordsList', data);
}

//医学论坛分类
export function medicalCategory() {
    return instance.get('medicalCategory');
}

//医学论坛-列表
export function medicalForum(data) {
    return instance.get('medicalForum', {params: data});
}

//患者诊疗订单-列表
export function diagnosisOrder(data) {
    return instance.get('diagnosisOrder', {params: data});
}

//医生附件-添加
export function doctorAttachmentAdd(data) {
    return instance.post('doctorAttachment/add', data);
}

//医生附件-列表
export function doctorAttachment(data) {
    return instance.get('doctorAttachment', {params: data});
}

//订单数量统计
export function orderCount(data) {
    return instance.get('order/orderCount', {params: data});
}

//添加患者处方记录
export function userPrescriptionAdd(data) {
    return instance.post('userPrescription/add', data);
}

//添加患者处方记录
export function userDiagnoseAdd(data) {
    return instance.post('userDiagnose/add', data);
}

//创建预约订单
export function appointmentAdd(data) {
    return instance.post('appointment/add', data);
}

//查询预约订单列表
export function appointment(data) {
    return instance.get('appointment', {params: data});
}

//获取订单状态列表
export function orderState() {
    return instance.get('order/orderState');
}

//文件上传
export function uploadFile(data) {
    return instance.post('common/uploadFile', data);
}

//医生端删除附件
export function uploadDel(data) {
    return instance.post('DoctorAttachment/del', data);
}

//轮播图
export function carouselImages() {
    return instance.get('carouselImages');
}

//配置项
export function config() {
    return instance.get('config');
}

//获取用户idlist
// export function recordsAdd(data) {
//     return instance.post('meeting_records/add',data);
// }
//编辑会议记录表
export function recordsEdit(data) {
    return instance.post('meeting_records/edit', data);
}

//获取医生列表
export function doctor_getList(data) {
    return instance.get('doctor/getList', {params: data});
}

//获取会议记录
export function meeting_records(data) {
    return instance.get('meeting_records', {params: data});
}

//获取今天全部的会议
export function meeting_recordsGetAll(data) {
    return instance.get('meeting_records/getAll', {params: data});
}

//医学论坛-添加
export function ForumAdd(data) {
    return instance.post('medicalForum/add', data);
}

//医学论坛评论-添加
export function medicalForumsComment(data) {
    return instance.post('medicalForumsComment/add', data);
}

//医学论坛评论-添加
export function medicalShow(data) {
    return instance.get('medicalForum/show', {params: data});
}

//医学论坛评论-列表
export function medicalForumsCommentList(data) {
    return instance.get('medicalForumsComment', {params: data});
}

//随访方案-添加
export function followUpSchemeAdd(data) {
    return instance.post('followUpScheme/add', data);
}

//获取疾病简介列表-全部
export function diseaseGetListAll() {
    return instance.get('disease/getListAll');
}

//随访方案配置项-列表
export function followUpSchemeConfig() {
    return instance.get('followUpSchemeConfig');
}

//随访方案-详情
export function followUpSchemeShow(data) {
    return instance.get('followUpScheme/show', {params: data});
}

//量表-列表
export function medicalScales(data) {
    return instance.get('medicalScales', {params: data});
}

//患教-列表
export function patientEducation(data) {
    return instance.get('patientEducation', {params: data});
}

//患教-详情
export function patientEducationShow(data) {
    return instance.get('patientEducation/show', {params: data});
}

//添加
export function schemeScalesEducationConfig(data) {
    return instance.post('schemeScalesEducationConfig/add', data);
}

//随访方案-列表
export function followUpScheme(data) {
    return instance.get('followUpScheme', {params: data});
}

//随访方案关联-列表
export function schemeScalesEducationConfigList(data) {
    return instance.get('schemeScalesEducationConfig', {params: data});
}

//随访方案-复制他人随访方案
export function followUpSchemeCopy(data) {
    return instance.post('followUpScheme/copy', data);
}

//随访方案关联-详情
export function schemeScalesEducationConfigShow(data) {
    return instance.get('schemeScalesEducationConfig/show', {params: data});
}

//随访方案关联-更新
export function schemeScalesEducationConfigEdit(data) {
    return instance.post('schemeScalesEducationConfig/edit', data);
}

//随访方案-列表 (我的侧边栏)
export function followUpSchemeGetListAll(data) {
    return instance.get('followUpScheme/getListAll', {params: data});
}

//随访 添加患者
export function userFollowUpSchemeAdd(data) {
    return instance.post('userFollowUpScheme/add', data);
}

//随访方案-生成微信小程序二维码
export function getFollowUpSchemeWxQRcode(data) {
    return instance.post('followUpScheme/getFollowUpSchemeWxQRcode', data);
}

//随访病人列表
export function userFollowUpScheme(data) {
    return instance.get('userFollowUpScheme', {params: data});
}

//随访病人列表详情
export function userFollowUpSchemeShow(data) {
    return instance.get('userFollowUpScheme/show', {params: data});
}

//随访方案答题列表
export function UserMedicalScalesAnswerShow(data) {
    return instance.get('UserMedicalScalesAnswer/show', {params: data});
}

//添加患者 确认发送
export function privateMessagesAdd(data) {
    return instance.post('privateMessages/add', data);
}

//添加患者 确认发送(群发)
export function privateMessagesAddList(data) {
    return instance.post('privateMessages/addList', data);
}

//患教文章添加
export function patientEducationAdd(data) {
    return instance.post('patientEducation/add', data);
}

//用户端我的消息
export function privateMessages(data) {
    return instance.get('privateMessages', {params: data});
}

//用户端我的消息
export function privateMessagesEdit(data) {
    return instance.post('privateMessages/edit', data);
}

//视屏培训 参会人员列表
export function meeting_recordsShow(data) {
    return instance.get('meeting_records/show', {params: data});
}

//修改会议房间信息
export function updateRoomInfo(data) {
    return instance.post('meeting_records/updateRoomInfo', data);
}

//修改会议房间信息
export function UserMedicalScalesAnswerEdit(data) {
    return instance.post('UserMedicalScalesAnswer/edit', data);
}

//生成会议记录文件
export function createMeetingRecordsFile(data) {
    return instance.post('meeting_records/createMeetingRecordsFile', data);
}

//会议记录-开始云录制(医生v医生)
export function startRecording(data) {
    return instance.post('meeting_records/startRecording', data);
}

//会议记录-结束云录制(医生v医生)
export function endRecording(data) {
    return instance.post('meeting_records/endRecording', data);
}

//医生排班-推荐名医-列表
export function getFamousDoctorsList(data) {
    return instance.get('schedules/getFamousDoctorsList', {params: data});
}

//医学论坛-删除
export function medicalForumDel(data) {
    return instance.post('medicalForum/del', data);
}


//会议 群组-添加
export function imMeetingGroupCreate(data) {
    return instance.post('https://im.ysjt1791.com/imMeetingGroup/create', data);
}

//会议 群组-添加
export function imMeetingGroupJoinGroup(data) {
    return instance.post('https://im.ysjt1791.com/imMeetingGroup/joinGroup', data);
}

//会议 群组-详情
export function imMeetingGroupShow(data) {
    return instance.get('https://im.ysjt1791.com/imMeetingGroup/show', {params: data});
}

//会议 群组-获取群组聊天记录
export function imMeetingGroupGetMsgList(data) {
    return instance.get('https://im.ysjt1791.com/imMeetingGroup/getMsgList', {params: data});
}

//会议 群组-获取群组聊天记录
export function imMeetingGroupSendToGroupMassage(data) {
    return instance.post('https://im.ysjt1791.com/imMeetingGroup/sendToGroupMassage', data);
}

//im 绑定用户
export function chatDinding(data) {
    return instance.post('https://im.ysjt1791.com/chat/binding', data);
}

//im 获取收件人的im_id
export function getReceiverImId(data) {
    return instance.post('https://im.ysjt1791.com/chat/getReceiverImId', data);
}

//im 发送消息
export function sendMassage(data) {
    return instance.post('https://im.ysjt1791.com/chat/sendMassage', data);
}

//im 获取聊天记录
export function getChatRecordsShow(data) {
    return instance.get('https://im.ysjt1791.com/chat/getChatRecordsShow', {params: data});
}

//im 群组-列表
export function imGroup(data) {
    return instance.get('https://im.ysjt1791.com/imGroup', {params: data});
}

//im 群组-创建
export function imGroup_add(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/create', data);
}

//im 群组-创建
export function imGroup_show(data) {
    return instance.get('https://im.ysjt1791.com/imGroup/show', {params: data});
}

//im 群组-获取群成员列表
export function getGroupMember(data) {
    if (data.group_id) {
        return instance.get('https://im.ysjt1791.com/imGroup/getGroupMember', {params: data});
    }
}

//im 群组-加入群组
export function joinGroup(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/joinGroup', data);
}

//im 群组-获取群组聊天记录
export function getMsgList(data) {
    return instance.get('https://im.ysjt1791.com/imGroup/getMsgList', {params: data});
}

//im 群组-发送群消息
export function sendToGroupMassage(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/sendToGroupMassage', data);
}

//im 群组-发送群消息 text_2
export function sendPcToGroupMassage(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/sendPcToGroupMassage', data);
}

//im 群组-更新
export function imGroupUpdate(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/update', data);
}

//群组-删除
export function imGroupDelete(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/delete', data);
}

//群组-生成小程序二维码
export function getImGroupWxQRcode(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/getImGroupWxQRcode', data);
}

//群组-管理群主成员(通过入群申请+踢出群组)
export function manageGroupUser(data) {
    return instance.post('https://im.ysjt1791.com/imGroup/manageGroupUser', data);
}

// 获取问卷调查题库
export function getQuestList() {
    return instance.get('user/getQuestList')
}

export function editUserQuest(data) {
    return instance.post('user/editUserQuest', data)
}

export function getUserQuest(params) {
    return instance.get('user/getUserQuest', {params})
}
