<template>
    <div class="wrapper">
<!--      <div class="list_name" style="overflow-y: auto">-->
<!--        <el-menu-->
<!--            style="width: 100%;"-->
<!--            @select="switchOut"-->
<!--            background-color="#f0f4f7"-->
<!--            default-active="1"-->

<!--        >-->
<!--          <el-menu-item index="1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">医生端</el-menu-item>-->
<!--&lt;!&ndash;          <el-menu-item index="2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">患者端</el-menu-item>&ndash;&gt;-->
<!--        </el-menu>-->
<!--        <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">-->
<!--      </div>-->
      <div class="content">
        <div style="display: flex;align-items: center;justify-content: space-between;height: 10%;">
          <div class="title">我的资料</div>
          <div class="title-right">
            <el-upload
                class="upload-demo"
                :action="Upload_Api_urls"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :headers="{ token: this.token }"
                :file-list="fileList">
              <el-button size="small" type="primary">上传</el-button>
              <div slot="tip" class="el-upload__tip"></div>
            </el-upload>
          </div>
        </div>

        <div class="counting">
          <!--            <el-table-->
          <!--                    :highlight-current-row="false"-->
          <!--                    :data="doctorAttachment_list"-->
          <!--                    style="width: 100%;">-->
          <!--                <el-table-column-->
          <!--                        prop="file_name"-->
          <!--                        label="文件名"-->
          <!--                        width="300">-->
          <!--                </el-table-column>-->
          <!--                <el-table-column-->
          <!--                        prop="create_time"-->
          <!--                        label="上传时间"-->
          <!--                        width="200">-->
          <!--                </el-table-column>-->
          <!--                <el-table-column-->
          <!--                        prop="file_size"-->
          <!--                        label="大小"-->
          <!--                        width="80">-->
          <!--                    <template slot-scope="scope">-->
          <!--                        {{ scope.row.file_size }} kb-->
          <!--                    </template>-->
          <!--                </el-table-column>-->
          <!--                <el-table-column-->
          <!--                        fixed="right"-->
          <!--                        label="操作"-->
          <!--                        width="120"-->
          <!--                style="z-index:1;">-->
          <!--                    <template slot-scope="scope">-->
          <!--                        <el-button-->
          <!--                                @click.native.prevent="download(scope.row)"-->
          <!--                                type="text"-->
          <!--                                size="small">-->
          <!--                            下载-->
          <!--                        </el-button>-->
          <!--                        <el-button-->
          <!--                                @click.native.prevent="deletefile(scope.row)"-->
          <!--                                type="text"-->
          <!--                                size="small">-->
          <!--                            移除-->
          <!--                        </el-button>-->
          <!--                    </template>-->
          <!--                </el-table-column>-->
          <!--            </el-table>-->

          <div style="height: 100%;overflow-y: auto;">
            <div class="history" v-for="(item,index) in doctorAttachment_list" :key="index">
              <div style="padding-bottom: 0.5rem;display: flex;align-items: center;width: 80%">
                <span class="date" style="font-weight: bold;width: 40%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ item.file_name }}</span>
                <span class="date" style="margin-left: 3%;width: 40%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ item.create_time }}</span>
                <span class="date" style="margin-left: 3%">{{ item.file_size }}</span>
              </div>
              <div style="display: flex;align-items: center;justify-content: center;font-weight: bold;font-size: 14px;width: 20%;">
                <div style="color: #0e92dd;cursor: pointer" @click="download(item)">下载</div>
                <div style="margin-left: 6%;color: #0e92dd;cursor: pointer" @click="deletefile(item)">移除</div>
                <div style="margin-left: 6%;color: #0e92dd;cursor: pointer" @click="preview(item)">预览</div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 90%;margin: auto;height: 7%;">
          （视频、图片、音频需打开后点击鼠标右键另存为）
        </div>
        <div class="con_below_page">
          <el-pagination
              :page-size="10"
              :pager-count="4"
              background
              layout="prev, pager, next"
              @current-change="file_page"
              :total="file_total">
          </el-pagination>
        </div>
      </div>
    </div>
</template>
<script>
import {onlyoffice, Upload_Api_url} from '@/api/index.js';
import {doctorAttachment, doctorAttachmentAdd, uploadDel} from "@/api";

export default {
    name: 'workLoads',
    data() {
        return {
            value: '',
            value1: '',
            value2: '',
            options: [{
                value: '0',
                label: '全部'
            },{
                value: '1',
                label: '在线问诊'
            }, {
                value: '2',
                label: '视频问诊'
            }],
            user_id: '',
            workloadList: [],
            user_info: [],
            order_type: '',
            start_time: '',
            order_count: [],
            doctorAttachment_list: [],
            token: '',
            fileList: [],
            Upload_Api_urls: '',
            page: 1,
            file_total: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.token = localStorage.getItem('token');
        this.info()
        this.Upload_Api_urls = Upload_Api_url
    },
    methods: {
      switchOut(e) {
        if (e == 2) {
          this.$emit('loginOuts')
        }
      },
        //列表分页
        file_page(e){
            this.page = e
            this.info()
        },
        info(){
            doctorAttachment({
                page: this.page,
                limit: 10,
                doctor_id: this.user_id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.doctorAttachment_list = res.data.data.data
                    this.file_total = Number(res.data.data.total)
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        download(e){
            window.open(e.file_url)
        },
        deletefile(e){
            uploadDel({
                doctor_id: this.user_id,
                doctor_attachment_id: e.id,
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.info()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //判断文件类型
        getFileType(fileName) {
            const fileExtension = fileName.split('.').pop().toLowerCase();
            switch (fileExtension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                    return 'image';
                case 'mp4':
                case 'webm':
                case 'ogg':
                    return 'video';
                case 'doc':
                case 'docx':
                    return 'word';
                case 'xls':
                case 'xlsx':
                    return 'excel';
                case 'pdf':
                    return 'pdf';
                case 'mp3':
                case 'wav':
                case 'aac':
                    return 'audio';
                default:
                    return 'unknown';
            }
        },
        preview(e){
          if (this.getFileType(e.file_name) === 'word' || this.getFileType(e.file_name) === 'excel'){
              const url = 'https://myg.ysjt1791.com/index.php/api/onlyoffice?id='+e.id
              window.open(url, '_blank');
          }else{
              window.open(e.file_url, '_blank');
          }
        },
        beforeUpload(file) {
            const isTxt = file.type === 'text/plain';
            if (isTxt) {
                this.$message.error('文件类型不支持');
                return false;
            }
            return true;
        },
        handleSuccess(e, file){
            this.fileList = [file];
            doctorAttachmentAdd({
                doctor_id: this.user_id,
                file_name: e.data['name'],
                file_url: e.data['file_path'],
                file_size: e.data['file_size'],
            }).then(res=>{
                if(res.data.code === 1){
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                    this.info()
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
.content{
    height: 100%;
    width: 100%;
    background-color: #f7f7f7cc;
    border: 1px #d6d8dc solid;
    z-index: 1;
}
.title{
    font-size: 16px;
    padding: 2% 0 0 5%;
    font-weight: 600;
}
.title-right{
    font-size: 16px;
    padding: 2% 5% 0 0;
    font-weight: 600;
}
.counting{
    width: 90%;
    height: 63%;
    margin: 1rem auto;
    border-radius: 10px;
    border: 2px #dddddd solid;
    overflow-y: auto;
}
.el-table th.el-table__cell .cell{
    text-align: center;
}
.el-table td.el-table__cell div{
    text-align: center;
}
.history{
    width: 95%;
    margin:  auto;
    padding: 0.5rem 0;
    border-bottom: 1px #ccc solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date{
    padding: 1.5% 0;
    font-size: 1.05rem;
}
.con_below_page{
    width: 95%;
    height: 10%;
    margin: 0.5% auto 0;
    display: flex;
    justify-content: center;
}
.list_name {
  position: relative;
  width: 200px;
  height: 100%;
  background: #f0f9ff;
}
.list_name .el-menu-item{
  padding: 0 !important;
  height: 40px;
  line-height: 40px;
  width: 100% !important;
  min-width: 100% !important;
}

.list_name .el-submenu__title{
  padding: 0 !important;
  height: 3rem;
}
.list_name .is-opened .el-submenu__title i {
  color: #fff !important;
}
.list_name .el-menu{
  width: 90%;
  border: none !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  height: 70vh;
}
</style>
