const appData = [
    {
        "codes": "1F600",
        "char": "😀",
        "name": "grinning face"
    },
    {
        "codes": "1F603",
        "char": "😃",
        "name": "grinning face with big eyes"
    },
    {
        "codes": "1F604",
        "char": "😄",
        "name": "grinning face with smiling eyes"
    },
    {
        "codes": "1F601",
        "char": "😁",
        "name": "beaming face with smiling eyes"
    },
    {
        "codes": "1F606",
        "char": "😆",
        "name": "grinning squinting face"
    },
    {
        "codes": "1F605",
        "char": "😅",
        "name": "grinning face with sweat"
    },
    {
        "codes": "1F923",
        "char": "🤣",
        "name": "rolling on the floor laughing"
    },
    {
        "codes": "1F602",
        "char": "😂",
        "name": "face with tears of joy"
    },
    {
        "codes": "1F642",
        "char": "🙂",
        "name": "slightly smiling face"
    },
    {
        "codes": "1F643",
        "char": "🙃",
        "name": "upside-down face"
    },
    {
        "codes": "1F609",
        "char": "😉",
        "name": "winking face"
    },
    {
        "codes": "1F60A",
        "char": "😊",
        "name": "smiling face with smiling eyes"
    },
    {
        "codes": "1F607",
        "char": "😇",
        "name": "smiling face with halo"
    },
    {
        "codes": "1F970",
        "char": "🥰",
        "name": "smiling face with hearts"
    },
    {
        "codes": "1F60D",
        "char": "😍",
        "name": "smiling face with heart-eyes"
    },
    {
        "codes": "1F929",
        "char": "🤩",
        "name": "star-struck"
    },
    {
        "codes": "1F618",
        "char": "😘",
        "name": "face blowing a kiss"
    },
    {
        "codes": "1F617",
        "char": "😗",
        "name": "kissing face"
    },
    {
        "codes": "1F61A",
        "char": "😚",
        "name": "kissing face with closed eyes"
    },
    {
        "codes": "1F619",
        "char": "😙",
        "name": "kissing face with smiling eyes"
    },
    {
        "codes": "1F44B",
        "char": "👋",
        "name": "waving hand"
    },
    {
        "codes": "1F91A",
        "char": "🤚",
        "name": "raised back of hand"
    },
    {
        "codes": "1F590",
        "char": "🖐",
        "name": "hand with fingers splayed"
    },
    {
        "codes": "270B",
        "char": "✋",
        "name": "raised hand"
    },
    {
        "codes": "1F596",
        "char": "🖖",
        "name": "vulcan salute"
    },
    {
        "codes": "1F44C",
        "char": "👌",
        "name": "OK hand"
    },
    {
        "codes": "1F90F",
        "char": "🤏",
        "name": "pinching hand"
    },
    {
        "codes": "270C",
        "char": "✌",
        "name": "victory hand"
    },
    {
        "codes": "1F91E",
        "char": "🤞",
        "name": "crossed fingers"
    },
    {
        "codes": "1F91F",
        "char": "🤟",
        "name": "love-you gesture"
    },
    {
        "codes": "1F918",
        "char": "🤘",
        "name": "sign of the horns"
    },
    {
        "codes": "1F919",
        "char": "🤙",
        "name": "call me hand"
    },
    {
        "codes": "1F448",
        "char": "👈",
        "name": "backhand index pointing left"
    },
    {
        "codes": "1F449",
        "char": "👉",
        "name": "backhand index pointing right"
    },
    {
        "codes": "1F446",
        "char": "👆",
        "name": "backhand index pointing up"
    },
    {
        "codes": "1F595",
        "char": "🖕",
        "name": "middle finger"
    },
    {
        "codes": "1F447",
        "char": "👇",
        "name": "backhand index pointing down"
    },
    {
        "codes": "261D FE0F",
        "char": "☝️",
        "name": "index pointing up"
    },
    {
        "codes": "1F44D",
        "char": "👍",
        "name": "thumbs up"
    },
    {
        "codes": "1F44E",
        "char": "👎",
        "name": "thumbs down"
    },
    {
        "codes": "270A",
        "char": "✊",
        "name": "raised fist"
    },
    {
        "codes": "1F44A",
        "char": "👊",
        "name": "oncoming fist"
    },
    {
        "codes": "1F91B",
        "char": "🤛",
        "name": "left-facing fist"
    },
    {
        "codes": "1F91C",
        "char": "🤜",
        "name": "right-facing fist"
    }
 
]
 
export default appData;