import { render, staticRenderFns } from "./comp-rooms.vue?vue&type=template&id=73b1909a&scoped=true"
import script from "./comp-rooms.vue?vue&type=script&lang=js"
export * from "./comp-rooms.vue?vue&type=script&lang=js"
import style0 from "./comp-rooms.vue?vue&type=style&index=0&id=73b1909a&prod&lang=css"
import style1 from "./comp-rooms.vue?vue&type=style&index=1&id=73b1909a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73b1909a",
  null
  
)

export default component.exports