<template>
  <div class="wrapper">
    <div class="list_name" style="overflow-y: auto">
<!--      <el-menu-->
<!--          style="width: 100%;"-->
<!--          @select="switchOut"-->
<!--          background-color="#f0f4f7"-->
<!--          default-active="2"-->

<!--      >-->
<!--        <el-menu-item index="1" style="text-indent: 1em;height: 3rem;line-height: 3rem;">医生端</el-menu-item>-->
<!--        <el-menu-item index="2" style="text-indent: 1em;height: 3rem;line-height: 3rem;">患者端</el-menu-item>-->
<!--      </el-menu>-->
<!--      <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">-->
      <div style="display: flex;flex-flow: column nowrap;align-items: center;">
        <div class="left-item" v-for="(item, index) in menuLeftList" :key="index" @click="changeTarget(item, index)">
          <div class="left-img" :class="{'active': menuIndex == index}">
            <img :src="item.icon" alt="Menu">
          </div>
          <div class="left-item-title" >{{item.name}}</div>
        </div>
      </div>
      <img src="/img/pa1.png" style="width: 100%;position: absolute;bottom: 0;object-fit: contain"  alt="">
    </div>
    <div class="content">
      <div class="title">
        <div class="title_left" @click="backPage">
          <i class="el-icon-back"></i>
        </div>
        个人档案
      </div>
      <div class="counting">
        <div class="counting_table">
          <div style="height: 100%;overflow-y: auto;">
            <table style="width: 100%;">
              <thead>
              <tr style="font-size: 15px">
                <th style="width: 12%;">序号</th>
                <th style="width: 35%;">时间</th>
                <th style="width: 20%;">上传者</th>
                <th style="width: 20%;">疾病</th>
                <th style="width: 25%;"></th>
              </tr>
              </thead>
              <tbody>
              <tr style="font-size: 14px;" v-for="(item,index) in userFilesList" :key="index">
                <td style="text-align: center;">{{ index + 1 }}</td>
                <td style="text-align: center;">{{ item.create_time }}</td>
                <td style="text-align: center;">{{ item.user.name }}</td>
                <td style="text-align: center;">{{ item.disease.name }}</td>
                <td style="text-align: center;">
                  <el-button style="width: 45%;font-weight: bold;margin: 5px 1%;padding: 3px 0;" type="primary" @click="informationDetail(item.disease)">查看</el-button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="counting_but">
          <el-button style="width: 20%;font-weight: bold;margin: 10px 0;" type="primary" @click="perfectInfoAdd">添加</el-button>
        </div>
      </div>

      <!--//档案信息详情-->
      <el-dialog title="疾病信息详情" :visible.sync="informationDetails" append-to-body width="30%">
        <div style="width: 90%;height: 500px;overflow-y: auto;padding: 20px;white-space: normal;margin: auto;" v-html="filesText"></div>
      </el-dialog>

      <el-dialog title="个人档案" :visible.sync="perfectInfo" width="40%" append-to-body>
        <!-- //完善个人档案|添加个人档案-->
        <add-files @filesAddComplete="filesAddComplete" v-if="perfectInfo"></add-files>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {medicalRecords} from "@/api";
import addFiles from "@/components/addFiles.vue";
export default {
    name: 'personalProfile',
    components: {addFiles},
    data() {
        return {
          menuIndex: 1,
          menuLeftList: [
            {
              icon: "/img/pa4.png",
              name: "中医",
              activeIcon: "/img/pa7.png",
              target: "chinese_doctor"
            },
            {
              icon: "/img/pa2.png",
              name: "医技",
              activeIcon: "/img/pa5.png",
              target: "doctor_technology"
            },
            {
              icon: "/img/pa3.png",
              name: "妇幼",
              activeIcon: "/img/pa6.png",
            }
          ],
            perfectInfo: false,
            informationDetails: false,
            filesText: '',
            userFilesList: [],
            user_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        this.filesList()
    },
    methods: {
      changeTarget(item, index) {
        this.menuIndex = index;
        // this.$emit("chioce", item.target)
        this.$emit('backPage', item.target);
      },
      switchOut(e) {
        if (e == 1) {
          this.$emit('loginOuts')
        }
      },
      backPage() {
        this.$emit('backPage')
      },
        perfectInfoAdd(){
            this.perfectInfo = true
        },
        //获取疾病详情信息
        informationDetail(item){
            this.informationDetails = true
            this.filesText = item.intro
        },
        //获取个人档案列表
        filesList(){
            medicalRecords().then(res=>{
                if(res.data.code === 1){
                    this.userFilesList = res.data.data.data
                }else if(res.data.code == 401){
                    this.$emit('loginOuts');
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        //完善档案成功回调
        filesAddComplete(){
            this.perfectInfo = false
            this.filesList()
            this.$message({
                message: '添加成功',
                type: 'success'
            });
        }
    }
}
</script>
<style scoped>
.content{
    height: 100%;
    width: calc(100% - 210px);
    background-color: #f7f7f7cc;
    border: 1px #d6d8dc solid;
    z-index: 1;
}
.title{
    font-size: 16px;
    padding: 2% 0 0 5%;
    font-weight: 600;
    text-align: center;
    position: relative;
    .title_left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-20%);
      font-size: 30px;
      font-weight: bolder;
      &:hover {
        color: #017ed8;
      }
    }
}
.counting{
    width: 90%;
    height: 70%;
    margin: 3% auto;
    border-radius: 10px;
    border: 2px #dddddd solid;
}
.counting_table{
    height: 75%;
    width: 100%;
}
.counting_but {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list_name {
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  width: 200px;
  height: 100%;
  background: #f0f9ff;
}
.list_name .el-menu-item{
  padding: 0 !important;
  height: 40px;
  line-height: 40px;
  width: 100% !important;
  min-width: 100% !important;
}

.list_name .el-submenu__title{
  padding: 0 !important;
  height: 3rem;
}
.list_name .is-opened .el-submenu__title i {
  color: #fff !important;
}
.list_name .el-menu{
  width: 90%;
  border: none !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 95vw;
  height: 70vh;
}
.left-item {
  color: #017ed8;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  .left-img {
    width: 87px;
    height: 87px;
    box-sizing: border-box;
    background: #D5E7F3;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
      height: 48px;

    }
  }
}
.left-img.active {
  background: #FFFFFF;
  border: 2px solid #017ED8;
}
</style>
