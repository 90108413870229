<template>
    <div class="content">
        <div class="list">
            <div class="list_search">
                <div style="width: 85%;">
                    <el-input size="mini" placeholder="搜索" v-model="input_name" @change="name_change">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div>
                    <el-popover width="50" trigger="click">
                        <template #reference>
                            <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer">
                                <i class="el-icon-plus" style="font-size: 20px;"></i>
                            </div>
                        </template>
                        <div style="display: flex;justify-content: center;align-items: center;padding: 0 20px;cursor: pointer;z-index: 9999;"
                            @click="find_group">
                            <div style="display: flex;align-items: center;"><img src="@/assets/find.png"
                                    style="width: 15px;height: 15px" alt="Vue Image"></div>
                            <div>查找群聊</div>
                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;padding: 10px 20px 0 20px;"
                            @click="add_group">
                            <div style="display: flex;align-items: center;"><img src="@/assets/add.png"
                                    style="width: 15px;height: 15px" alt="Vue Image"></div>
                            <div>创建群聊</div>
                        </div>
                    </el-popover>

                </div>
            </div>
            <div class="list_name_g">
                <el-menu background-color="transparent" default-active="Group-0" class="el-menu-vertical-demo"
                    @open="handleOpen" @close="handleClose" @select="handleSelect">
                    <el-menu-item :index="'Group-' + group_index" v-for="(group_item, group_index) in Group_list"
                        :key="group_index">
                        <div slot="title"
                            style="display: flex;justify-content: space-between;align-items: center; padding-left: 16px;">
                            <div style="width: 55%;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{
                                group_item.name }}</div>
                            <div style="width: 40%;">{{ group_item.last_message_time }}</div>
                        </div>
                    </el-menu-item>
                </el-menu>
            </div>
            <!-- <div class="menu-router-bg"></div> -->
        </div>
        <!--      :w="1281"-->
        <draggable-resizable style="width: calc(100% - 210px);" h="100%" :x="0" @mousedown.native.stop="onMouseDown"
            @touchstart.native.stop="onMouseDown" :y="0">
            <div class="con" v-if="is_Group === 2">
                <div class="con_top">
                    <div style="display: flex;align-items: center;">
                        <div style="color: #fff;font-weight: bold;padding-left: 10px;" @click="aaa">{{ info.name }} ({{
                            info.group_num }}) </div>
                        <div style="width: 15px;height: 15px;margin-left: 15px;" @click="edit_name(info)"
                            v-if="info.creator_id === user_im_id"><img src="@/assets/edit.png"
                                style="width: 100%;height: 100%" alt="Vue Image"></div>
                    </div>
                    <el-button size="small" type="danger" style="margin-left: auto"
                        v-if="info.creator_id === user_im_id" @click="dissolve_group_chat">解散群聊</el-button>
                    <div style="display: flex;align-items: center;margin-right: 20px;">
                        <div class="con_img"><img src="@/assets/erweima.png" style="width: 100%;height: 100%"
                                alt="Vue Image" @click="get_code(info)"></div>
                        <div class="con_img"><img src="@/assets/drag.png" style="width: 100%;height: 100%"
                                alt="Vue Image">
                        </div>
                        <div class="con_img"><img src="@/assets/miniaturization.png" style="width: 100%;height: 100%"
                                alt="Vue Image" @click="minimum_talk"></div>
                    </div>
                </div>
                <div class="con_below">
                    <div class="con_below_left">
                        <div class="con_below_left_top" ref="scrollArea">
                            <div v-for="(list_item, list_index) in history" :key="list_index">
                                <div style="text-align: left;" v-if="list_item.im_binding.doctor.id !== user_id">
                                    <div style="padding: 8px 10px 0 0;">
                                        <div style="padding: 2px 10px;">{{ list_item.im_binding.doctor.name }}</div>

                                        <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;"
                                            v-if="list_item.msg_type === 'text_2' || list_item.msg_type === 'text'">
                                            {{ list_item.content }}
                                        </div>
                                        <div @click="play_audio(list_item.content)"
                                            style="margin: 2px 10px;padding: 8px 8px;background-color: #017ED8;border-radius: 5px;display: inline-block;cursor: pointer;"
                                            v-if="list_item.msg_type === 'audio' || list_item.msg_type === 'audio_2'">
                                            <div
                                                style="width: 80px;display: flex;align-items: center;justify-content: left">
                                                <div style="display: flex;align-items: center;justify-content: left">
                                                    <div style="display: flex;align-items: center;">
                                                        <img src="@/assets/audios.png" style="width: 15px;height: 15px"
                                                            alt="Vue Image">
                                                    </div>
                                                    <div
                                                        style="font-size: 16px;color: #ffffff;display: flex;align-items: center;">
                                                        {{ list_item.audio_time }}s
                                                    </div>
                                                </div>
                                                <div style="display: flex;align-items: center;justify-content: left">
                                                    <!--                                                <div style="font-size: 16px;color: #ffffff;display: flex;align-items: center;margin-left: 1rem">-->
                                                    <!--                                                    <img src="@/assets/audios1.png" style="width: 15px;height: 15px" alt="Vue Image">-->
                                                    <!--                                                </div>-->
                                                    <!--                                                <div style="font-size: 16px;color: #ffffff;display: flex;align-items: center;">-->
                                                    <!--                                                    <img src="@/assets/audios1.png" style="width: 15px;height: 15px" alt="Vue Image">-->
                                                    <!--                                                </div>-->
                                                    <img src="@/assets/yyyyyyy.png" v-if="is_audio === 1"
                                                        style="width: 50px;height: 30px" alt="Vue Image">
                                                    <img src="@/assets/yyyyy.gif" v-else
                                                        style="width: 50px;height: 30px" alt="Vue Image">
                                                </div>

                                            </div>
                                        </div>
                                        <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;"
                                            v-if="list_item.msg_type === 'file' || list_item.msg_type === 'file_2'">
                                            <a class="file_style" :download="list_item.file_name"
                                                :href="list_item.content" target="_blank">{{ list_item.file_name }}</a>
                                        </div>
                                        <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;"
                                            v-if="list_item.msg_type === 'image' || list_item.msg_type === 'image_2'">
                                            <el-image style="width: 100px; height: 100px" :src="list_item.content"
                                                :preview-src-list="[list_item.content]">
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                                <div style="text-align: right;" v-else>
                                    <div style="padding: 8px 10px 0 0;">
                                        <div style="padding: 2px 10px;">{{ list_item.im_binding.doctor.name }}</div>
                                        <div style="margin: 2px 10px;padding: 8px 8px;background-color: #017ED8;border-radius: 5px;display: inline-block;color: #fff;text-align: left;"
                                            v-if="list_item.msg_type === 'text_2' || list_item.msg_type === 'text'">
                                            {{ list_item.content }}
                                        </div>
                                        <div style="margin: 2px 10px;padding: 8px 8px;background-color: #017ED8;border-radius: 5px;display: inline-block;"
                                            v-if="list_item.msg_type === 'file' || list_item.msg_type === 'file_2'">
                                            <a class="file_style" style="color: #fff" :download="list_item.file_name"
                                                :href="list_item.content" target="_blank">{{ list_item.file_name }}</a>
                                        </div>
                                        <div @click="play_audio(list_item.content)"
                                            style="margin: 2px 10px;padding: 8px 8px;background-color: #017ED8;border-radius: 5px;display: inline-block;cursor: pointer;"
                                            v-if="list_item.msg_type === 'audio' || list_item.msg_type === 'audio_2'">
                                            <!--                                        <audio controls style="width: 200px;background-color: #cf9236">-->
                                            <!--                                            <source :src="list_item.content" type="audio/mp3">-->
                                            <!--                                        </audio>-->
                                            <div
                                                style="width: 80px;display: flex;align-items: center;justify-content: left">
                                                <div style="display: flex;align-items: center;justify-content: left">
                                                    <div style="display: flex;align-items: center;">
                                                        <img src="@/assets/audios.png" style="width: 15px;height: 15px"
                                                            alt="Vue Image">
                                                    </div>
                                                    <div
                                                        style="font-size: 16px;color: #ffffff;display: flex;align-items: center;">
                                                        {{ list_item.audio_time }}s
                                                    </div>
                                                </div>
                                                <div style="display: flex;align-items: center;justify-content: left">
                                                    <img src="@/assets/yyyyyyy.png" v-if="is_audio === 1"
                                                        style="width: 50px;height: 30px" alt="Vue Image">
                                                    <img src="@/assets/yyyyy.gif" v-else
                                                        style="width: 50px;height: 30px" alt="Vue Image">
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin: 2px 10px;padding: 8px 8px;background-color: #e5e7eb;border-radius: 5px;display: inline-block;"
                                            v-if="list_item.msg_type === 'image' || list_item.msg_type === 'image_2'">
                                            <el-image style="width: 100px; height: 100px" :src="list_item.content"
                                                :preview-src-list="[list_item.content]">
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="con_below_left_below">
                            <el-input v-model="send_input" placeholder="输入内容" size="small"
                                @keyup.native.enter="Send"></el-input>
                            <div class="comment_input_button">
                                <div style="width: 1px;height: 18px;background: #b2afaf;margin: 0 8px;"></div>
                                <div style="width: 20px;height: 20px;" @click="open"><img src="@/assets/flie.png"
                                        style="width: 100%;height: 100%" alt="Vue Image"></div>
                                <div style="width: 1px;height: 18px;background: #b2afaf;margin: 0 8px;"></div>
                                <div style="width: 20px;height: 20px;border-radius: 8px 8px 8px 8px;border: 1px solid #017ED8;display: flex;justify-content: center;align-items: center;"
                                    @click="audio">
                                    <img src="@/assets/audio_frequency.png" style="width: 15px;height: 15px"
                                        alt="Vue Image">
                                </div>
                                <div style="width: 1px;height: 18px;background: #b2afaf;margin: 0 8px;"></div>
                                <div style="width: 22px;height: 22px;background: #017ED8;border-radius: 8px;display: flex;justify-content: center;align-items: center;"
                                    @click="Send">
                                    <img src="@/assets/send.png" style="width: 18px;height: 18px" alt="Vue Image">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="con_below_right">
                        <div class="con_below_right_top">
                            <div
                                style="display: flex;justify-content: space-between;align-items: center;width: 90%;margin: auto;height: 100%">
                                <div style="padding: 10px 5px;height: 100%;">
                                    <div
                                        style="font-weight: bold;font-size: 15px;padding-top: 10px;padding-bottom: 10px;">
                                        群公告
                                    </div>
                                    <div style="overflow-y: auto;height: 80%;">{{ info.notice }}</div>
                                </div>
                                <div style="padding: 10px 5px;" @click="edit_announcement(info)"
                                    v-if="info.creator_id === user_im_id">
                                    <img src="@/assets/edit1.png" style="width: 15px;height: 15px" alt="Vue Image">
                                </div>
                            </div>
                        </div>
                        <div class="con_below_right_below">
                            <div
                                style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin: auto;padding: 10px 0;">
                                <div style="font-weight: bold;font-size: 15px;">群成员{{ GroupMember_arr.ol_count }}/{{
                                    GroupMember_arr.all_count }}</div>
                                <div style="display: flex;">
                                    <div @click="application"><img src="@/assets/userlist.png"
                                            style="width: 15px;height: 15px" alt="Vue Image"></div>
                                    <div @click="manage_members"><img src="@/assets/settings1.png"
                                            style="margin-left: 5px;width: 15px;height: 15px" alt="Vue Image"></div>
                                </div>
                            </div>
                            <div style="display: flex;justify-content: space-between;align-items:center;width: 90%;margin: auto;padding: 2px 0;"
                                v-for="(name_item, name_index) in GroupMember_arr.user_list" :key="name_index">
                                <div style="display: flex;justify-content: left;align-items:center;">
                                    <div v-if="name_item.is_online === '1'"><img src="@/assets/dian.png"
                                            style="width: 15px;height: 15px" alt="Vue Image"></div>
                                    <div v-else><img src="@/assets/dian1.png" style="width: 15px;height: 15px"
                                            alt="Vue Image">
                                    </div>
                                    <div style="font-size: 14px;">{{ name_item.name }}</div>
                                </div>
                                <div>
                                    <div v-if="name_item.is_group_owner === '1'"><img src="@/assets/group_leader.png"
                                            style="width: 15px;height: 15px" alt="Vue Image"></div>
                                </div>
                            </div>
                        </div>
                        <div class="con_below_right_below_but">
                            <!--                        <el-button size="small" type="danger"-->
                            <!--                                   v-if="info.creator_id === user_im_id" @click="dissolve_group_chat">解散群聊</el-button>-->
                        </div>
                    </div>
                </div>
            </div>
        </draggable-resizable>


        <el-dialog title="查找群聊" :visible.sync="find_group_type" append-to-body>
            <div style="width: 90%;margin: auto;">
                <el-input placeholder="请输入内容" v-model="find_group_text" style="margin-top: 2rem;">
                    <template slot="append">
                        <div @click="search_group_but">
                            <i class="el-icon-search"></i> 搜索
                        </div>
                    </template>
                </el-input>
                <div style="padding: 10px 5px;color: #a0a0a0" v-if="find_group_array.length > 0">找到{{
                    find_group_array.length
                    }}个群</div>
                <div style="padding: 2px 5px;display: flex;align-items: center;"
                    v-for="(item, index) in find_group_array" :key="index">
                    <div>{{ item.name }}</div>
                    <div style="width: 50px;border-radius: 3px;border: 1px #017ED8 solid;margin-left: 10px;cursor: pointer;"
                        @click="join_group_but(item)"><i class="el-icon-plus" style="color: #017ED8;"></i> 加入</div>
                </div>
            </div>
            <div style="height: 20px;"></div>
        </el-dialog>

        <el-dialog title="创建群聊" :visible.sync="add_group_type" append-to-body>
            <div style="width: 90%;margin: auto;">
                <div style="display: flex;align-items: center;margin-top: 20px">
                    <div style="width: 10%;">群名称: </div>
                    <div style="width: 90%;"><el-input v-model="add_input" placeholder="请输入群名称"></el-input></div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="add_group_but">确 定</el-button>
                <el-button @click="add_group_type = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="修改群名称" :visible.sync="edit_group_name" append-to-body>
            <div style="width: 90%;margin: auto;">
                <div style="display: flex;align-items: center;margin-top: 20px">
                    <div style="width: 10%;">群名称: </div>
                    <div style="width: 90%;"><el-input v-model="edit_name_input" placeholder="请输入群名称"></el-input></div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="edit_group_but">确 定</el-button>
                <el-button @click="edit_group_name = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="修改群公告" :visible.sync="edit_group_announcement">
            <div style="width: 90%;margin: auto;">
                <div style="display: flex;align-items: center;margin-top: 20px">
                    <div style="width: 10%;">群公告: </div>
                    <div style="width: 90%;"><el-input v-model="announcement_name_input"
                            placeholder="请输入群名称"></el-input></div>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="announcement_group_but">确 定</el-button>
                <el-button @click="edit_group_announcement = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog title="群聊二维码" :visible.sync="code_image_type">
            <div style="display: flex;justify-content: center;padding: 10px 0;">
                <el-image :src="code_image"></el-image>
            </div>
        </el-dialog>

        <el-dialog title="管理群成员" :visible.sync="manage_members_type">
            <div style="width: 90%;height: 200px;margin: auto;padding: 2%;overflow-y: auto;">
                <div style="padding: 1% 5px;display: flex;align-items: center;"
                    v-for="(item, index) in GroupMember_arr.user_list" :key="index">
                    <div style="width: 20%;">{{ item.name }}</div>
                    <div style="width: 50px;border-radius: 3px;border: 1px #017ED8 solid;margin-left: 10px;cursor: pointer;text-align: center;"
                        @click="kick_out(item)">踢出</div>
                </div>
            </div>
            <div style="height: 20px;"></div>
        </el-dialog>

        <el-dialog title="申请列表" :visible.sync="application_type">
            <div style="width: 90%;height: 200px;margin: auto;padding: 2%;overflow-y: auto;">
                <div style="padding: 1% 5px;display: flex;align-items: center;"
                    v-for="(item, index) in application_arr.user_list" :key="index">
                    <div style="width: 20%;">{{ item.name }}</div>
                    <div style="width: 50px;border-radius: 3px;border: 1px #017ED8 solid;margin-left: 10px;cursor: pointer;text-align: center;"
                        @click="agree(item)">同意</div>
                </div>
            </div>
            <div style="height: 20px;"></div>
        </el-dialog>

        <!-- 上传文件 -->
        <input type="file" style="display: none;" @change="uploads" accept=".jpeg,.png,.jpg,.gif"
            ref="File" multiple />

    </div>
</template>
<script>
import {
    chatDinding,
    getGroupMember, getImGroupWxQRcode,
    getMsgList,
    imGroup,
    imGroup_add,
    imGroup_show, imGroupDelete, imGroupUpdate,
    joinGroup, manageGroupUser, sendPcToGroupMassage,
    sendToGroupMassage, uploadFile
} from "@/api";
import DraggableResizable from 'vue-draggable-resizable';
export default {
    name: 'forumList',
    components: {
        DraggableResizable,
    },
    data () {
        return {
            is_audio: 1,
            find_group_text: '',
            input: '',
            find_group_type: false,
            add_group_type: false,
            edit_group_name: false,
            edit_group_info: [],
            edit_name_input: '',
            client_id: '',
            user_id: '',
            user_role: '',
            token: '',
            user_im_id: localStorage.getItem('user_im_id'),
            Group_list: [],
            add_input: '',
            Group_id: '',
            is_Group: 1,
            info: [],
            GroupMember_arr: [],
            history: [],
            input_name: '',
            send_input: '',
            find_group_array: [],
            edit_group_announcement: false,
            edit_announcements: [],
            announcement_name_input: '',
            isRecording: false, // 标记是否正在录音
            mediaRecorder: null, // 媒体录音器
            chunks: [], // 存储录音块
            hasMicrophone: true, // 标记是否检测到麦克风
            audioDuration: '',
            is_audio_playing: false,
            code_image: '',//群聊二维码
            code_image_type: false,//是否显示群聊二维码
            manage_members_type: false,//管理群成员弹窗
            application_arr: [],//申请进群的成员列表
            application_type: false,//申请进群成员列表弹窗
        }
    },
    mounted () {
        this.client_id = localStorage.getItem('client_id');
        this.token = localStorage.getItem('token')
        this.user_id = localStorage.getItem('user_id');
        this.user_role = localStorage.getItem('user_role');
        this.init()
    },
    beforeDestroy () {
        document.body.style.userSelect = '';
        document.body.style.webkitUserSelect = '';
        document.body.style.msUserSelect = '';
        document.body.style.mozUserSelect = '';
    },
    methods: {
        //获取群聊二维码
        get_code (array) {
            console.log(array.id)
            getImGroupWxQRcode({
                id: array.id,
            }).then(res => {
                if (res.data.code === '1') {
                    this.code_image = res.data.data.url
                    this.code_image_type = true
                } else {
                    this.$message({
                        message: '网络错误',
                        type: 'warning'
                    });
                }
            })
        },
        //管理群成员
        manage_members () {
            console.log(this.GroupMember_arr.user_list)
            this.manage_members_type = true
        },
        //踢出成员
        kick_out (array) {
            console.log(array)
            manageGroupUser({
                group_id: this.Group_id,
                im_id: this.user_im_id,
                operate_im_id: array.im_id,
                is_audit: '2',
            }).then(res => {
                if (res.data.code === '1') {
                    this.$message({
                        message: '踢出成功',
                        type: 'success'
                    });
                    this.Group_members()
                    this.manage_members_type = false
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //申请列表
        application () {
            getGroupMember({
                group_id: this.Group_id,
                is_audit: 0,
            }).then(res => {
                if (res.data.code === '1') {
                    this.application_arr = res.data.data
                    this.application_type = true
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //同意进群
        agree (array) {
            console.log(array)
            manageGroupUser({
                group_id: this.Group_id,
                im_id: this.user_im_id,
                operate_im_id: array.im_id,
                is_audit: '1',
            }).then(res => {
                if (res.data.code === '1') {
                    this.$message({
                        message: '加入成功',
                        type: 'success'
                    });
                    this.application()
                    this.application_type = false
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        minimum_talk () {
            this.is_Group = 1
        },
        onMouseDown () {
            document.body.style.userSelect = 'none';
            document.body.style.webkitUserSelect = 'none'; // For Safari
            document.body.style.msUserSelect = 'none'; // For IE
            document.body.style.mozUserSelect = 'none'; // For Firefox
        },
        dissolve_group_chat () {
            console.log(this.Group_id)
            imGroupDelete({
                id: this.Group_id,
                creator_id: this.user_im_id,
            }).then(res => {
                if (res.data.code === '1') {
                    this.$message({
                        message: '解散成功',
                        type: 'success'
                    });
                    this.is_Group = 1
                    this.Group_init()
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        aaa () {
            console.log(this.history)
        },
        //发送消息
        Send () {
            sendPcToGroupMassage({
                group_id: this.Group_id,
                sender_im_id: this.user_im_id,
                msg_type: 'text',
                content: this.send_input,
            }).then(res => {
                if (res.data.code === '1') {

                    this.send_input = ''
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        groupdata (e) {
          console.log('groupdata',e,this.Group_id === e.group_id)
            if (this.Group_id === e.group_id) {
                this.history.push(e)
                this.scrollToBottom()
            }
        },
        //搜索群列表
        name_change (e) {
            this.input_name = e
            this.Group_init()
        },
        scrollToBottom () {
            this.$nextTick(() => {
                console.log(this.$refs.scrollArea.scrollTop, this.$refs.scrollArea.scrollHeight)
                this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
                console.log(this.$refs.scrollArea.scrollTop, this.$refs.scrollArea.scrollHeight)
            });
        },
        handleOpen (key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose (key, keyPath) {
            console.log(key, keyPath);
        },
        find_group () {
            console.log(111)
            this.find_group_type = true
        },
        add_group () {
            console.log(222)
            this.add_group_type = true
        },
        //获取群聊列表
        Group_init () {
            imGroup({
                im_id: this.user_im_id,
                name: this.input_name,
            }).then(res => {
                if (res.data.code === '1') {
                    this.Group_list = res.data.data
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取自己的以及对方的im_id
        init () {
            chatDinding({
                user_id: this.user_id,
                role: this.user_role,
                client_id: this.client_id,
            }).then(res => {
                if (res.data.code === "1") {
                    this.user_im_id = res.data.data.im_id
                    console.log(this.user_im_id)
                    this.Group_init()
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //确定创建群聊
        add_group_but () {
            console.log(this.user_im_id, this.add_input)
            imGroup_add({
                im_id: this.user_im_id,
                name: this.add_input,
            }).then(res => {
                if (res.data.code === '1') {
                    this.$message({
                        message: '创建成功',
                        type: 'success'
                    });
                    this.add_group_type = false
                    this.Group_init()
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //搜搜群聊
        search_group_but () {
            imGroup({
                group_num: this.find_group_text,
            }).then(res => {
                if (res.data.code === '1') {
                    this.find_group_array = res.data.data
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //加入群聊
        join_group_but (item) {
            this.group_id = item.id
            joinGroup({
                im_id: this.user_im_id,
                group_id: item.id
            }).then(res => {
                if (res.data.code !== '1') {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                } else {
                    this.Group_init()
                    this.find_group_type = false
                    this.find_group_text = ''
                    this.find_group_array = []
                }
            })

        },
        //选择群聊
        handleSelect (e) {
            this.is_Group = 2
            const id = e.split('-').pop()
            const ids = this.Group_list[id].id
            this.Group_id = ids
            this.Chat_history()
            this.Group_info()
            this.join()
        },
        //获取群详情
        Group_info () {
            imGroup_show({
                id: this.Group_id,
            }).then(res => {
                if (res.data.code === '1') {
                    this.info = res.data.data
                    this.Group_members()
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取群成员列表
        Group_members (e = 0) {
            getGroupMember({
                group_id: e ? e : this.Group_id
            }).then(res => {
                if (res.data.code === '1') {
                    this.GroupMember_arr = res.data.data
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //加入群组
        join () {
            joinGroup({
                im_id: this.user_im_id,
                group_id: this.Group_id
            }).then(res => {
                if (res.data.code !== '1') {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //获取群组聊天记录
        Chat_history () {
            getMsgList({
                group_id: this.Group_id,
            }).then(res => {
                if (res.data.code === '1') {
                    this.history = res.data.data.data
                    this.scrollToBottom()
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //修改群名称
        edit_name (array) {
            this.edit_group_name = true
            this.edit_group_info = array
            this.edit_name_input = this.edit_group_info.name
        },
        //确定修改群名称
        edit_group_but () {
            console.log(this.edit_name_input)
            imGroupUpdate({
                id: this.edit_group_info.id,
                name: this.edit_name_input
            }).then(res => {
                if (res.data.code === '1') {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.Group_init()
                    this.Group_info()
                    this.edit_group_name = false
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //修改群名称
        edit_announcement (array) {
            this.edit_group_announcement = true
            this.edit_announcements = array
            this.announcement_name_input = this.edit_announcements.notice
        },
        //确定修改群名称
        announcement_group_but () {
            console.log(this.edit_name_input)
            imGroupUpdate({
                id: this.edit_announcements.id,
                notice: this.announcement_name_input
            }).then(res => {
                if (res.data.code === '1') {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.Group_info()
                    this.edit_group_announcement = false
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        //图片上传选择文件
        open () {
            const typeFile = this.$refs['File'];
            typeFile.click();
        },
        uploads (event) {
            const file = event.target.files;
            if (file) {
                const formData = new FormData();
                for (let i = 0; i < file.length; i++) {
                    formData.append('file', file[i]);
                }
                uploadFile(formData).then(res => {
                    if (res.data.code === 1) {
                      sendPcToGroupMassage({
                            group_id: this.Group_id,
                            sender_im_id: this.user_im_id,
                            msg_type: 'image',
                            file_name: res.data.data.name,
                            content: res.data.data.path,
                        }).then(res => {
                            if (res.data.code === '1') {
                                console.log(res)
                                this.send_input = ''
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                });
                            }
                        })
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })
            }
        },
        //音频
        audio () {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    this.hasMicrophone = devices.some(device => device.kind === 'audioinput');
                    if (!this.hasMicrophone) {
                        this.$message({
                            message: '请检查麦克风',
                            type: 'warning'
                        });
                    } else {
                        this.$emit('tape', 1);
                        if (!this.isRecording) {
                            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                                const options = { mimeType: 'audio/webm' };
                                this.mediaRecorder = new MediaRecorder(stream, options);
                                this.mediaRecorder.start();
                                this.isRecording = true;
                                this.mediaRecorder.addEventListener('dataavailable', (e) => {
                                    if (e.data.size > 0) {
                                        this.chunks.push(e.data);
                                    }
                                });
                            });
                        }
                    }
                })
                .catch(error => {
                    console.error('检查麦克风设备时出错:', error);
                    this.$message({
                        message: '请检查麦克风',
                        type: 'warning'
                    });
                });
        },
        send_tape () {
            if (this.isRecording) {
                this.mediaRecorder.addEventListener('stop', () => {
                    this.$emit('tape', 2);
                    const blob = new Blob(this.chunks, { 'type': 'audio/webm' });
                    const bitrate = 158; // 假设比特率为 128 kbps
                    const fileSizeInBits = blob.size * 8;
                    const durationInSeconds = fileSizeInBits / (bitrate * 1000);
                    this.audioDuration = Math.ceil(durationInSeconds);
                    const formData = new FormData();
                    formData.append('file', blob, 'audio.webm');
                    uploadFile(formData).then(res => {
                        if (res.data.code === 1) {
                            console.log(res.data)
                          sendPcToGroupMassage({
                                group_id: this.Group_id,
                                sender_im_id: this.user_im_id,
                                msg_type: 'audio',
                                audio_time: this.audioDuration,
                                file_name: res.data.data.name,
                                content: res.data.data.path,
                            }).then(res => {
                                if (res.data.code === '1') {
                                    console.log(res)
                                    this.send_input = ''
                                } else {
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    });
                                }
                            })
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                    this.chunks = [];
                    this.mediaRecorder = null;
                    this.isRecording = false;
                });
                this.mediaRecorder.stop();
            }
        },
        close_tape () {
            this.$emit('tape', 2);
            if (this.isRecording) {
                // 停止录音并重置状态
                this.mediaRecorder.stop();
                // 清空chunks并释放mediaRecorder资源
                this.chunks = [];
                this.mediaRecorder = null;
                this.isRecording = false;
                this.$message({
                    message: '取消发送',
                    type: 'warning'
                });
            }
        },
        //播放语音
        play_audio (url) {
            this.is_audio = 2
            let audio = new Audio();
            audio.src = url;
            if (this.is_audio_playing) {
                // 如果正在播放，则暂停
                audio.pause();
            } else {
                // 如果没有在播放，则开始播放，并添加结束监听
                audio.addEventListener('ended', () => {
                    console.log('音频播放完毕');
                    this.is_audio = 1
                    this.is_audio_playing = false; // 音频播放完毕，更新状态
                });
                audio.play();
                this.is_audio_playing = true;
            }
        }
    }
}
</script>
<style>
.list_search .el-input__inner {
    background-color: #e2eaf3;
}

.list_name_g .el-menu-item {
    padding: 0 3% !important;
    height: 40px;
    line-height: 40px;
    background-color: transparent !important;
}

.el-menu-item.is-active {
    background-color: #017ED8 !important;
    color: #fff !important;
}

.content .el-input-group__append {
    background-color: #017ED8;
    color: #fff;
    font-size: 15px;
}

.con_below_left_below .el-input__inner {
    padding-right: 10rem !important;
}

.el-carousel__item.is-active {
    z-index: 0 !important;
}

.el-carousel__indicator--horizontal {
    display: none;
}
</style>
<style scoped>
draggable-resizable {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    position: fixed !important;
    z-index: 9999 !important;
}

.content {
    position: relative;
    height: 90%;
    width: 95vw;
    background-color: transparent;
    /* border: 1px #d6d8dc solid; */
    display: flex;
    justify-content: center;
}

.list {
    margin-right: 19px;
    position: relative;
    min-width: 200px;

    height: 100%;
    width: fit-content;
    padding: 0 0 0 10px;
    background: #F0F9FF;
}

.con {
    width: 100%;
    height: 100%;
    background: #ffffff;
}

.list_search {
    width: 200px;
    height: 10%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
}

.list_name_g {
    height: 90%;
    background-color: #F0F9FF;
    margin: auto;
    overflow-y: auto;
    box-sizing: border-box;

    background-image: url('../assets/image/safe.png');
    background-size: 100% 100%;
}

.con_top {
    width: 100%;
    //height: 7%;
    height: 60px;
    background-color: #017ED8;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.con_img {
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.con_below {
    box-sizing: border-box;
    background-color: #F0F9FF;
    width: 100%;
    padding: 16px;
    height: 93%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.con_below_left {
    width: calc(100% - 231px);
    height: 100%;
}

.con_below_left_top {
    height: 90%;
    overflow-y: scroll;
}

.con_below_left_below {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    position: relative;
}

.con_below_right {
    width: 231px;
    height: 100%;
}

.con_below_right_top {
    width: 100%;
    height: 166px;
    background-color: #FAFDFF;
}

.con_below_right_below {
    width: 100%;
    height: calc(100% - 166px);
    overflow-y: auto;
    background-color: #ffffff;
}

.con_below_right_below_but {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.comment_input_button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;
}

.menu-router-bg {
    z-index: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 306px;
    background-image: url('../assets/image/safe.png');
    background-size: 100% 100%;
}
</style>
