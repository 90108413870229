/**
 * 第一步：与socket建立链接
 * 打开一个 web socket (以下链接方式任选一种即可)
 * 参数解释：var ws = new WebSocket("参数A://参数B:参数C");
 * 参数A：固定写法
 ws：表示http连接
 wss:表示https连接,有https证书的时候使用
 * 参数B：要连接的地址。
 可选参数：127.0.0.1 表示：本地地址
 可选参数：48.662.156.869 表示：你的云服务器的公网ip  （48.662.156.869这个ip是我假设的云服务器公网ip）
 可选参数：im_dev.liutong.pro 表示：这个你的http域名 如（http://im_dev.liutong.pro）
 可选参数：im.liutong.pro 表示：这个是你的https网址域名 如（https://im.liutong.pro）
 * 参数C：端口号
 端口号是可以自定义的（注意取值范围）。记得在服务器->实例安全组中进行放行。
 端口号取值范围：端口不能大于65535，请确认端口没有被其它程序占用，否则启动会报错。如果端口小于1024，需要root权限运行GatewayWorker才能有权限监听，否则报错没有权限。(详情参见手册->《Gateway类的使用》->"初始化"->"ip")
 注意：这里的端口号必须和你在 "项目名\GatewayWorker\Applications\YourApp\start_gateway.php" 中$gateway = new Gateway("websocket://0.0.0.0:8282");这段代码的端口号保持一致。
 */
    // var ws = new WebSocket("ws://127.0.0.1:8282");//进行webSocket的几种方式（方式A：常规操作）
    // var ws = new WebSocket("ws://47.104.249.193:8282");//进行webSocket的几种方式（方式B：使用本机公网ip进行连接）
    // var ws = new WebSocket("ws://jim.liutong.pro:8282");//进行webSocket的几种方式（方式C.1:使用http://jim.liutong.pro的方式进行连接）
    // var ws = new WebSocket("wss://jim.liutong.pro:8282");//进行webSocket的几种方式（方式C.2:使用https://jim.liutong.pro的方式进行连接）

const wsservice = (el) => {
        return new Promise((resolve, reject) => {
            // const ws = new WebSocket("wss://mygim.zeyan.wang:8282");
            const ws = new WebSocket("wss://im.ysjt1791.com:8282");

            ws.onopen = function () {
                console.log("连接成功");
            };

            ws.onclose = function () {
                console.log("连接已关闭...");
                reject("连接已关闭...")
            };

            ws.onmessage = function (e) {
                try {
                    var data = eval("(" + e.data + ")");
                    var type = data.msg_type || '';
                    resolve({'event': 'onmessage', data}); // Resolve the promise with the data
                    switch (type) {
                        case 'init':
                            localStorage.setItem('client_id', data.client_id);
                            setInterval(function() {
                                var heartbeatData = { timestamp: data.client_id };
                                ws.send(JSON.stringify(heartbeatData));
                            }, 3000); // 20秒发送一次心跳数据，根据需要调整时间间隔
                            resolve({'event': 'init', data}); // Resolve the promise with the data
                            break;
                        case 'text':
                            console.log('我是text文本消息', data);
                            el.wssetdata(data)
                            resolve({'event': 'text', data}); // Resolve the promise with the data
                            break;
                        case 'text_1':
                            console.log('接消息')
                            el.videodata(data)
                            break;
                        case 'text_2':
                        case 'image_2':
                        case 'file_2':
                        case 'audio_2':
                            console.log('群接消息')
                            el.groupdata(data)
                            break;

                        case 'close_order':
                            console.log('结束订单', data.content);
                            resolve({'event': 'text', data}); // Resolve the promise with the data
                            el.wssetdata(data)
                            break;
                        case 'image':
                            console.log('我是收到的image消息', data.content);
                            resolve({'event': 'image', data}); // Resolve the promise with the data
                            el.wssetdata(data)
                            break;
                        case 'image_1':
                            el.videodata(data)
                            break;
                        case 'audio':
                            el.groupdata(data)
                            console.log('我是收到的语音消息', data.content);
                            resolve({'event': 'audio', data}); // Resolve the promise with the data
                            break;
                        case 'ping':
                            console.log('11111');
                            break;
                        case 'roomID':
                            console.log(data,'豆腐干豆腐干大锅饭的风格');
                            break;
                        case 'group_join_new':
                            console.log(data,'加入群聊');
                            el.update_userlist(data.group_id)
                            break;
                        case 'group_close':
                            console.log(data,'群聊解散');
                            el.update_userlist(data.group_id)
                            break;
                        case 'group_kicking':
                            console.log(data,'踢人');
                            el.update_userlist(data)
                            break;
                        default:
                        // layer.msg('连接失败,请刷新页面后再试')
                    }
                } catch (err) {
                    console.log({err, e})
                }

            };
        });
    }

export default wsservice
