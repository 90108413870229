import { render, staticRenderFns } from "./medicalTechnology.vue?vue&type=template&id=61e98cdc&scoped=true"
import script from "./medicalTechnology.vue?vue&type=script&lang=js"
export * from "./medicalTechnology.vue?vue&type=script&lang=js"
import style0 from "./medicalTechnology.vue?vue&type=style&index=0&id=61e98cdc&prod&lang=css"
import style1 from "./medicalTechnology.vue?vue&type=style&index=1&id=61e98cdc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e98cdc",
  null
  
)

export default component.exports